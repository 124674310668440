@import '~variables';
@import '~mixins';

.ticket-wrapper-steps {
    position: relative;
    display: flex;
    margin: toRem(10) 0 toRem(20) 0;

    &__text {
        margin-right: toRem(5);
    }
}
