@import '~variables';
@import '~mixins';

.location-form {
  &__map-wrapper {
    margin: toRem(30) 0;
  }

  .ant-layout-footer {
    background: transparent;
  }

  &.drawer-form {
    height: auto;
  }
}
