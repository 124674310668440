@import '~variables';
@import '~mixins';

.ant-collapse,
.ant-collapse-content-active.ant-collapse-content-active {
  border: none;
}

.ant-collapse-item.ant-collapse-item {
  border-bottom: $inputBorder;
}

.collapse-wrapper {
  border: $inputBorder;
}

.ant-collapse-header.ant-collapse-header {
  font-size: toRem(16);
  font-weight: 600;
}

.ant-collapse-header[aria-expanded='true'] {
  border-bottom: $inputBorder;
  border: none;
}

.ant-collapse-header {
  background: white;

  .ant-collapse-header-text {
    text-transform: capitalize;
  }
}
