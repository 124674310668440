@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.documents_buttons_container {
  text-align: center;
  margin-bottom: toRem(8);
  @include respond-above(xs) {
    text-align: left;
    margin-bottom: 0;
  }
}
