@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.ant-modal-wrap {
  z-index: 2000;
}
.preview-modal {
  .ant-modal-content {
    width: fit-content;
    max-height: 80vh;
    overflow-y: auto;
    padding: toRem(8);
    .ant-modal-body {
      height: 100%;
      padding: toRem(35) toRem(10) toRem(20) toRem(10);
    }
    img {
      width: 70vh;
      max-width: calc(100vw - #{toRem(16)});
    }
    .file-viewer-wrapper {
      height: 100%;
      width: 70vh;
      max-width: calc(100vw - #{toRem(16)});
      .ant-modal-body {
        height: 100% !important;
        padding: toRem(35) toRem(10) toRem(20) toRem(10);
      }
    }
    .pg-viewer-wrapper {
      overflow: hidden;
      .react-grid-Grid {
        .react-grid-Viewport {
          height: 50vh !important;
          position: relative !important;
          inset: 0 !important;
          div {
            font-size: toRem(12);
          }
        }
      }
      .document-container {
        width: 100% !important;
      }
    }
  }
}
