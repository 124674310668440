@import '~variables';
@import '~mixins';

.home {
  max-width: toRem(1600);
  margin-left: auto;
  margin-right: auto;
}

.home__header {
  height: toRem(64);
  padding: toRem(16) toRem(20);
  display: flex;
  justify-content: space-between;
}

.home__modules {
  display: flex;
  justify-content: center;
}

.home__wrapper {
  font-family: $defaultFont;
  width: 100%;
  height: calc(100vh - 64px);
  padding: 8%;
  text-align: center;
  background-color: $primaryGray;
}

.home__title {
  margin-bottom: toRem(42);
  font-size: toRem(23);
  font-weight: 700;
}

.home__svg {
  height: toRem(24);
  width: 100%;
  font-size: toRem(24);
}

.home__module {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;

  &:hover {
    box-shadow: $buttonBoxShadow;
  }
}

.home__module-title {
  font-weight: 600;
  font-size: toRem(16);
  line-height: toRem(24);
  margin: toRem(8) 0;
}

.home__module-description {
  font-style: normal;
  font-weight: 400;
  font-size: toRem(12);
  color: $secondaryTextColor;
}

.home__subscription {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: toRem(8);
}

.home__subscription-tag {
  font-weight: 500;
  font-size: toRem(16);
  line-height: toRem(28);
}
