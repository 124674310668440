@import '~variables';
@import '~mediaQueries';

.account-balance-wrapper {
  display: flex;

  .button.ant-btn {
    height: toRem(39);
    min-width: toRem(60);
    align-self: center;
    font-size: toRem(12);
    border-radius: 0 toRem(4) toRem(4) 0;
    padding: toRem(8);
    @include respond-above(xl) {
      font-size: toRem(14);
    }
  }
  .account-balance-text-wrapper {
    height: toRem(39);
    display: flex;
    align-items: center;
    padding: toRem(8) toRem(10);
    border-radius: toRem(4) 0 0 toRem(4);
    background-color: $primaryWhite;
    border: toRem(1) solid $inputBlue;
    border-right: none;
  }
  .account-balance-text {
    height: toRem(39);
    color: $primaryOrange;
    font-weight: 500;
    font-size: toRem(12);
    display: flex;
    align-items: center;
    margin-bottom: 0;
    @include respond-above(xl) {
      font-size: toRem(14);
    }
  }
  .ant-skeleton-element .ant-skeleton-input {
    width: toRem(160);
    min-width: toRem(160);
    height: toRem(20);
    vertical-align: middle;
    margin-right: toRem(16);
  }
  .account-balance-amount-wrapper {
    position: relative;
    display: flex;

    .account-balance-spinner {
      position: absolute;
      font-size: toRem(24);
      color: $primaryBlue;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(toRem(1));
    }
    .account-balance-spinner__hidden {
      display: none;
    }
  }
}
.account-balance__hidden {
  display: none;
}
