@import '~variables';
@import '~mixins';

.header {
  &.ant-layout-header {
    position: fixed;
    top: 0;
    margin-left: toRem(25);
    padding-left: toRem(25);
    padding-right: toRem(25);
    max-width: calc(#{toRem(1280)} - #{toRem(25)});
    width: -webkit-fill-available;
    height: toRem($headerHeight);
    display: flex;
    align-items: center;
    z-index: 1;

    .right-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding: 0 toRem(16);
    }

    .left-header {
      display: flex;
      flex: 0 0 toRem(280);
      max-width: toRem(280);
      min-width: toRem(280);
      width: toRem(280);
      height: 100%;
      border-right: $grayBorder;

      svg {
        height: toRem(40);
      }

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: toRem(199);

        a {
          display: flex;
          align-content: center;
        }
      }
    }

    .search {
      color: $primaryGrayDark;
      width: 20%;
      height: 50%;

      input,
      input:focus,
      input:active,
      input:hover {
        border: 0;
        background: transparent;
      }

      .ant-input-prefix {
        color: $primaryGrayDark;
        font-size: toRem(24);
      }
    }
  }
}
