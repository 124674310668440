@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.purchase {
  display: none;
}
.purchase__logo {
  height: toRem(30);
  width: toRem(120);
}
.purchase__logo-wrapper {
  :hover {
    cursor: pointer;
  }
  align-self: self-end;
  margin-bottom: 0;
  height: toRem(30);
}
.purchase-title {
  display: none;
}

.purchase,
.purchase__inner-header {
  padding: 0 toRem(8) toRem(24) toRem(8);
  margin: 0 auto;
  max-width: map-get($breakpoints, lg);
}
.purchase__inner-header {
  padding: toRem(16) 0;
}

.purchase__header {
  position: fixed;
  z-index: 1;
  top: 0;
  background: $primaryGray;
  width: 100%;
  left: 0;

  &--scrolling {
    border-bottom: toRem(1) solid $primaryWhite;
    box-shadow: $primaryBoxShadow;
  }
}

.purchase__cart {
  position: relative;
  display: flex;
  flex-flow: column;
  cursor: pointer;
  height: toRem(30);
}

.purchase__cart-icon {
  font-size: toRem(30);
  color: $primaryBlueNew;
  width: toRem(120);
  margin-bottom: toRem(-3);
}

.purchase__counter-wrapper {
  position: absolute;
  top: 0;
  right: toRem(37);
  background-color: rgba($primaryOrange, 0.75);
  border-radius: toRem(25);
  width: toRem(17);
  height: toRem(17);
  display: flex;
  justify-content: center;
}
.purchase__counter {
  font-size: toRem(11);
  color: $primaryWhite;
  font-weight: 500;
  white-space: inherit;
}

.purchase:nth-child(2) {
  margin-top: toRem(270);
}
.purchase__divider .ant-divider-inner-text {
  height: toRem(27);
  text-transform: capitalize;
  font-size: toRem(13);
}
.purchase .ant-divider-horizontal.ant-divider-with-text {
  margin: toRem(8) 0;
}

.purchase__basic-list {
  min-height: toRem(240);
  display: flex;
  flex-flow: column;
  gap: toRem(6);
}

.purchase .ant-checkbox-wrapper {
  font-size: toRem(12);
  line-height: 1;
}
.purchase .purchase__basic-list {
  font-size: toRem(12);
}

.purchase .form-item-wrapper .ant-checkbox-wrapper .ant-checkbox-inner {
  height: toRem(12);
  width: toRem(12);
}
.purchase-addon__checkbox {
  color: $primaryGreen;
  font-size: toRem(10);
}

.purchase .ant-collapse-header {
  background: transparent;
}

.purchase__buy-now {
  position: fixed;
  bottom: toRem(15);
  right: toRem(100);
}

.purchase
  .form-item-wrapper
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  height: toRem(25);
  background: $primaryWhite;
  border-radius: toRem(4);

  span {
    color: $primaryButtonOrange;
  }
}
.purchase .form-item-wrapper .ant-radio-button-wrapper {
  height: toRem(25);
}

.purchase .form-item-wrapper .ant-radio-button-wrapper span {
  font-size: toRem(12);
  line-height: initial;
  display: flex;
  font-family: $defaultFont;
  font-style: normal;
  font-weight: 400;

  color: $primaryWhite;
}
.purchase .form-item-wrapper .ant-radio-group {
  padding-top: toRem(4);
  padding-bottom: toRem(4);
}
.purchase
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: $primaryButtonOrange;
}

@include respond-above(sm) {
  .purchase {
    display: block;
  }
  .mobile-purchase {
    display: none;
  }
  .purchase,
  .purchase__inner-header {
    padding: 0 toRem(24) toRem(24) toRem(24);
  }
  .purchase__inner-header {
    padding: toRem(24);
  }
}

@include respond-above(md) {
  .purchase-title {
    align-self: flex-end;
    margin-bottom: 0;
    display: flex;
  }

  .purchase-title__mobile {
    display: none;
  }

  .purchase,
  .purchase__inner-header {
    max-width: map-get($breakpoints, md);
  }
  .purchase__inner-header {
    padding: toRem(24) 0;
  }

  .purchase:nth-child(2) {
    margin-top: toRem(230);
  }
}

@include respond-above(xl) {
  .purchase,
  .purchase__inner-header {
    max-width: map-get($breakpoints, lg);
  }
  .purchase__inner-header {
    padding: toRem(24) 0 toRem(32) 0;
  }
  .purchase__cart-icon {
    font-size: toRem(35);
  }
  .purchase__cart-title {
    font-size: toRem(12);
  }

  .purchase
    .form-item-wrapper
    .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    height: toRem(32);
  }
  .purchase .form-item-wrapper .ant-radio-button-wrapper {
    height: toRem(32);
  }

  .purchase .form-item-wrapper .ant-radio-button-wrapper span {
    font-size: toRem(18);
    line-height: toRem(21);
  }
  .purchase .form-item-wrapper .ant-radio-group {
    padding: toRem(11) toRem(13);
  }

  .purchase .purchase__basic-list {
    font-size: toRem(14);
  }
  .purchase__basic-list-title {
    display: flex;
    font-size: toRem(14);
  }

  .purchase__basic-list {
    min-height: toRem(285);
    display: flex;
    flex-flow: column;
    gap: toRem(6);
  }

  .purchase:nth-child(2) {
    margin-top: toRem(270);
  }
  .purchase__divider .ant-divider-inner-text {
    font-size: toRem(16);
  }
  .purchase .ant-divider-horizontal.ant-divider-with-text {
    margin: toRem(16) 0;
  }
}
