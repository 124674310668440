@import '~variables';
@import '~mixins';

.select {
  width: toRem(116);

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer;
    background-color: $primaryGray;
    height: toRem(50);
  }
  .ant-select-selector {
    display: flex;
    align-items: center;
    margin-right: toRem(5);
    .ant-select-selection-search {
      padding-top: toRem(10);
    }
  }

  .ant-select-dropdown {
    width: 10.625rem !important;
  }
}
