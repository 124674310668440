@import '~variables';
@import '~mixins';

.table-sorting {
  &__inner-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__buttons-container {
    display: flex;
    min-width: toRem(100);
  }
  &__inner-button {
    width: toRem(65);
    max-height: toRem(30);
    background-color: $primaryWhite;
    border: $grayBorder;
    border-radius: toRem(4);
    margin-right: 0 !important;
    margin-left: toRem(4);
    cursor: pointer;
    &--active {
      background-color: $primaryOrange;
      color: $primaryWhite;
    }
    &:hover {
      box-shadow: 0 toRem(2) toRem(8) $colorShadowDefault;
    }
  }

  &__container {
    width: toRem(250);
    background: $primaryWhite;
    border: $grayBorder;
    border-radius: toRem(4);
  }

  &__menu-header {
    height: toRem(40);
    color: $primaryGrayDark;
    padding-top: toRem(8);
    padding-left: toRem(12);
  }

  &__item {
    height: toRem(38);
    font-size: toRem(14);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: toRem(8) toRem(12);

    &--active {
      font-weight: 600;
      background: $colorBackgroundGrayDark;
    }
  }

  &__icon {
    width: toRem(20);
  }
}

@media screen and (max-width: $modernMobileScreen + 'px') {
  .table-sorting {
    &__button {
      &.ant-btn {
        margin-left: toRem(4);
      }
    }
  }
}
