@import '~variables';
@import '~mixins';

.wysiwyg-editor-wrapper {
  width: 100%;

  .ant-form-item-label {
    margin-bottom: toRem(5);
  }
  .ant-row:first-child {
    height: 100%;
  }
  .rdw-start-aligned-block {
    display: none;
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
  .wysiwyg-editor.wysiwyg-editor--mb-0.rdw-editor-wrapper {
    border-radius: toRem(14);
  }
}
