@import '~variables';
@import '~mixins';
@import '~mediaQueries';

$darkColor: #222230;

.purchase-cart__divider.purchase-cart__divider.purchase-cart__divider {
  margin-top: toRem(20);
}

.purchase-cart-drawer .ant-drawer-content-wrapper {
  width: 42% !important;
  min-width: toRem(300);

  & h3 {
    font-size: toRem(16);
    font-weight: bold;
  }
  & p {
    margin-bottom: toRem(4);
  }
}

.purchase-cart-drawer .ant-divider-horizontal {
  margin: 0;
}

.purchase-cart-drawer .ant-divider-inner-text {
  color: $primaryGrayDark;
  font-size: toRem(14);
}

.purchase-cart-drawer table {
  width: 100%;
}

.purchase-cart-drawer tr {
  margin-bottom: toRem(8);
}

.purchase-cart-drawer td,
th {
  min-width: toRem(110);

  &:first-child {
    width: 100%;
    text-align: left;
  }

  &:last-child {
    text-align: right;
  }
}

.purchase-cart-drawer th {
  font-weight: 700;
  font-size: toRem(10);
  letter-spacing: toRem(0.5);
  color: #747487;
  text-transform: uppercase;
}

.purchase-cart-drawer__prices td {
  font-size: toRem(12);
  color: $darkColor;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: toRem(20);
  height: toRem(40);
  position: relative;
}

.purchase-cart-drawer__price-cycle {
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: 400;
  color: $darkColor;
  font-size: toRem(12);
  text-transform: capitalize;
}

.purchase-cart-drawer__recurring {
  & td {
    font-size: toRem(12);
    color: $darkColor;
    text-transform: capitalize;
    height: toRem(32);
  }
}

.purchase-cart-drawer__charge {
  height: toRem(45);
  & td {
    font-size: toRem(12);
    color: $darkColor;
    font-weight: 600;
    text-transform: capitalize;
  }
  & tr:first-child {
    position: relative;
    & p:nth-child(2) {
      position: absolute;
      top: toRem(30);
      right: 0;
    }
  }
}

.purchase-cart-drawer__taxes-sub-title {
  text-align: right;
  bottom: 0;
  font-size: toRem(10);
  color: #72727b;
  margin-top: toRem(2);
}

@include respond-above(xl) {
  .purchase-cart-drawer .ant-drawer-content-wrapper {
    & p {
      margin-bottom: 0;
    }
  }
  .purchase-cart-drawer .ant-divider-inner-text {
    font-size: toRem(16);
  }
  .purchase-cart-drawer__prices td {
    font-size: toRem(14);
    height: toRem(50);
  }
  .purchase-cart-drawer__recurring {
    & td {
      font-size: toRem(14);
    }
  }
  .purchase-cart-drawer__charge {
    & td {
      font-size: toRem(12);
    }
  }
}
.purchase-cart-drawer__stripe-icon {
  height: toRem(30);
  width: toRem(130);
  @include respond-above(md) {
    height: toRem(45);
    width: toRem(200);
  }
}
.purchase-cart-drawer .button.ant-btn {
  height: toRem(30);
  min-width: toRem(70);
  @include respond-above(md) {
    height: toRem(39);
    min-width: toRem(98);
  }
}
