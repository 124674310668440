@import '~mixins';
@import '~variables';

.go-back-button {
  &.ant-btn {
    margin-right: toRem(10);
    font-weight: 500;
    padding: 0 toRem(8);
    width: toRem(60);
    border: 0;
    background: transparent;
    &:hover {
      background: 0;
      color: $primaryBlueNew;
    }
  }
}
