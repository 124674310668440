@import '~mixins';

.table-values {

  &__location-container {
    width: toRem(100);
  }

  &__incidents-progressbar {
    display: flex;
    align-items: center;
    .anticon {
      margin-left: toRem(5);
    }
    .ant-tooltip-inner {
      font-size: toRem(12);
      span {
        width: toRem(100);
        display: inline-block;
      }
    }
    .ant-progress {
      width: toRem(140);
      margin-right: toRem(10);
    }
  }
}