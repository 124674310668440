@import '~mixins';
@import '~variables';
@import '~mediaQueries';

.form {
  position: relative;

  &--loading form {
    filter: $filter;
  }
}

.form__spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.form__copy-button {
  margin: 0 toRem(16);
}

.product-form__form-grid {
  .product-header__product-number {
    margin-top: toRem(24);
  }

  .ant-input-number {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .col-flex--one {
    width: 100%;
  }
  .col-flex--one-currency {
    width: 50%;
  }
  &.ant-input-number {
    width: 100%;
  }
  .ant-collapse {
    padding-bottom: toRem(32);
    .ant-collapse-item {
      .ant-collapse-header {
        padding-bottom: toRem(32);
      }

      .ant-collapse-content-box {
        padding: 0;
        .col-flex--weekday {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(50%, 2fr));
          .button-wrapper {
            display: flex;
            justify-content: flex-start;
            width: 25%;
          }
          .form-item-wrapper {
            &:last-child {
              margin-right: toRem(16);
            }
          }
          .ant-btn-icon-only {
            align-self: center;
            margin-left: 25%;
          }
          @include respond-above(md) {
            display: flex;
            justify-content: space-between;
            .form-item-wrapper {
              &:nth-child(1) {
                margin-right: toRem(16);
              }
              width: 100%;
            }
          }
        }
        .col-flex--holiday {
          display: flex;
          width: 100%;
          .button-wrapper {
            display: flex;
            align-items: center;
            width: 50%;
          }
          @include respond-above(md) {
            display: flex;
            width: 50%;
            .ant-btn-icon-only {
              align-self: center;
            }
          }
        }
      }
    }
    &.col-flex__holidays-container {
      width: 100%;
    }
  }
  .col-flex--two {
    display: grid;
    .ant-picker {
      width: 100%;
    }
    .form-item-wrapper {
      width: calc(100% - 16px / 2);
      .ant-form-item-label {
        display: flex;
        min-width: 100%;
        .ant-form-item-required {
          min-width: 100%;
        }
      }

      &:nth-child(1) {
        margin-right: toRem(16);
      }
    }
    width: 100%;
    &.col-flex--two-mobile {
      display: grid;
      grid-template-columns: 2fr 50%;
    }
    @include respond-above(md) {
      display: flex;
      width: 100%;
      .form-item-wrapper {
        width: calc(100% - 16px / 2);

        &:nth-child(1) {
          margin-right: toRem(16);
        }
      }
    }
  }
  .col-flex--two-nested {
    width: auto;
    display: grid;
    grid-template-areas:
      'location location'
      'col1 col2';
    width: 100%;
    .form-item-wrapper {
      &:first-child {
        width: 100%;
        grid-area: location;
      }
      &:nth-child(2) {
        width: 100%;
        grid-area: col1;
        padding-right: toRem(8);
      }
      &:nth-child(3) {
        width: 100%;
        grid-area: col2;
        align-self: self-end;
      }
    }

    @include respond-above(md) {
      display: flex;
      width: 100%;
      .form-item-wrapper {
        .ant-form-item-label label {
          display: flex;
          width: 100%;
        }
        .ant-input-number {
          width: 100% !important;
          display: flex;
          .ant-input-number-input-wrap {
            align-self: center;
          }
        }
        &:nth-child(1) {
          width: 100%;
        }
        &:not(first-child) {
          width: 50%;
          align-self: self-end;
        }
        &:nth-child(2) {
          padding: 0 toRem(16);
        }
        .ant-col {
          &.ant-form-item-label {
            width: 100%;
            .ant-form-item-required {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .col-flex--three {
    width: auto;
    display: block;
    width: 100%;
    @include respond-above(md) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .form-item-wrapper {
        width: calc(100% - 16px / 3);

        &:nth-child(2) {
          margin: 0 toRem(16);
          align-self: self-end;
        }
        &:last-child {
          align-self: center;
          margin: 0;
          .ant-row {
            &.ant-form-item {
              &.ant-form-item__checkbox {
                margin-bottom: 0;
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .col-flex--four {
    width: auto;
    display: grid;
    grid-template-columns: 2fr 50%;
    width: 100%;
    .form-item-wrapper {
      width: 100%;
      &:nth-child(even) {
        margin: 0 toRem(8);
        align-self: self-end;
      }
    }
    @include respond-above(md) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .form-item-wrapper {
        width: calc(100% - 16px / 4);

        &:nth-child(2) {
          margin: 0 toRem(16);
          align-self: self-start;
        }
        &:nth-child(4) {
          margin: 0 toRem(16);
          align-self: flex-end;
        }
      }
    }
  }

  .col-flex--four-with-unit {
    width: auto;
    display: grid;
    grid-template-columns: 2fr 50%;
    width: 100%;
    .form-item-wrapper {
      width: 100%;
      &:nth-child(2) {
        margin: 0 toRem(8);
        align-self: self-end;
      }
      &:nth-child(4) {
        margin: toRem(40) toRem(8);
        align-self: self-end;
      }
    }
    @include respond-above(md) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .form-item-wrapper {
        width: calc(100% - 16px / 4);

        &:nth-child(2) {
          margin: 0 toRem(16);
          align-self: self-start;
        }
        &:nth-child(4) {
          margin: toRem(32) toRem(16);
          align-self: flex-end;
        }
      }
    }
  }

  .add-weekday-policy {
    margin-top: toRem(16);
    margin-bottom: toRem(16);
    width: 100%;
    button {
      padding: unset;
      width: 100%;
    }
  }
}

.form-permission {
  color: $primaryRed;
}
