@import '~variables';
@import '~mixins';

.submit-form-btns {
  &.ant-layout-footer {
    background: 0 !important;
    padding: toRem(15) 0 0 0;
    border-top: $grayBorder;
  }
}
