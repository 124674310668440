@import '~variables';
@import '~mixins';
@import '~mediaQueries';

$yellow: #ffdf32;
$gray: #d4d4d4;
$lightBlack: rgba(18, 29, 42, 0.3);
$black: #121d2a;
$white: #ffffff;
$background: #f6f8fa;

.services-styled-wrapper .ant-radio-group,
.services-styled-wrapper .ant-checkbox-group {
  display: flex;
  gap: toRem(8);
  background: $white;
  border-radius: toRem(8);
}

.services-styled-wrapper .ant-checkbox-wrapper,
.services-styled-wrapper .ant-radio-wrapper {
  width: 100%;
  height: toRem(42);
  margin-right: 0;
  font-family: $primaryFont;
  font-style: normal;
  font-weight: 400;
  font-size: toRem(16);
  line-height: toRem(19);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $black;
  transition: 500ms;
}

.services-styled-wrapper .ant-radio-wrapper::before {
  display: none;
}

.services-styled-wrapper .ant-checkbox-wrapper-checked,
.services-styled-wrapper .ant-radio-wrapper-checked {
  background: $yellow;
  font-weight: 500;
  color: $primaryBlackLight;
  border: none;
  border-radius: toRem(4);
}

.services-styled-wrapper
  .ant-radio-wrapper-checked:not(.ant-radio-wrapper-disabled) {
  cursor: default;
}

.services-styled-wrapper .ant-radio {
  display: none;
}

.services-styled-wrapper .ant-checkbox {
  display: none;
}

.services-styled-wrapper .ant-form-item-explain-error {
  margin-left: toRem(16);
}

.services-styled-wrapper .ant-select-selection-placeholder {
  font-family: $primaryFont;
  font-style: normal;
  font-weight: 400;
  font-size: toRem(12);
  line-height: toRem(14);
  color: $lightBlack;
}

.services-styled-wrapper
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: toRem(42);
  background: $background;
  border-radius: toRem(4);
  border-color: $background;
}


.services-styled-wrapper
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: $yellow;
  box-shadow: none;
}
