@import '~variables';
@import '~mixins';

.user-settings {
  &__spinner {
    width: 100%;
    height: 100%;
  }

  &__submit-button {
    margin-bottom: toRem(30);
  }

  &__location {
    label,
    .ant-form-item-label {
      width: 100%;
    }
  }

  &__location-label {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.user-settings__title {
  color: $colorFontDefault;
  font-size: toRem(28);
  margin-bottom: toRem(20);
}
