@import '~variables';
@import '~mixins';

.time-logs {
  padding: 0;

  &--fixed-column {
    background-color: $primaryWhite;
  }
}

.time-logs table {
  width: auto !important;
}

.time-logs .summary {
  width: toRem(200);
}

.time-logs .time-logs__row-disabled {
  cursor: not-allowed;
  background: $inputDisabledGrey;

  & > .ant-table-cell.ant-table-cell-row-hover {
    background: none !important;
  }

  &:hover {
    background: $inputDisabledGrey !important;
  }
}

.time-logs__row-disabled > .ant-table-cell-row-hover {
  background: none !important;
}

.time-logs .ant-table-cell > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.time-logs .ant-table-cell > div > div {
  max-width: 200px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
