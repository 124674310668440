@import '~variables';
@import '~mixins';

.incident-task-new {
  border-top: $grayBorder;
  padding-top: toRem(30);

  input {
    color: $colorFontDefault !important;
    background: 0 !important;
  }

  .editor-wrapper {
    min-height: auto;
  }

  &__details {
    .anticon-info-circle {
      margin-left: toRem(5);
    }
    .ant-popover-inner-content {
      padding: toRem(15);
    }
  }
}
