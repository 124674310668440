@import '~mixins';
@import '~variables';

.finance-dashboard-tile {
  position: relative;
  width: 100%;
  height: toRem(150);
}

.finance-dashboard-tile__skeleton {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.finance-dashboard-tile__title {
  margin-bottom: toRem(26);
  font-size: toRem(16);
  line-height: toRem(24);
  letter-spacing: toRem(0.15);
  color: $colorFontDefault;
  font-weight: 600;
}

.finance-dashboard-tile__total {
  position: relative;
  width: fit-content;
  margin-bottom: toRem(10);
  font-weight: 500;
  font-size: toRem(20);
  letter-spacing: 0;
  color: $colorFontDefault;
}

.finance-dashboard-tile__compare-total {
  position: absolute;
  left: calc(100% + #{toRem(8)});
  top: 0;
  width: max-content;
  font-weight: 400;
  font-size: toRem(12);
  line-height: toRem(20);
  letter-spacing: toRem(0.4);
  color: $primaryGreen;

  &--negative {
    color: $primaryRed;
  }
}

.finance-dashboard-tile__label {
  padding-left: toRem(8);
  padding-right: toRem(8);
  max-width: 100%;
  height: toRem(20);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $colorBackgroundBlue;
  font-size: toRem(12);
  font-weight: 500;
  color: $primaryBlue;
  cursor: default;
  border-radius: toRem(16);
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.finance-dashboard-tile__image {
  position: absolute;
  bottom: 0;
  right: 0;
  height: toRem(190);
  width: toRem(105);
  object-fit: cover;
}
