@import '~mixins';
@import '~variables';

.resolution-form {
  thead.ant-table-thead > tr,
  tbody.ant-table-tbody > tr {
    height: toRem(20);
  }

  .ant-table-tbody > tr > td {
    padding: toRem(10) toRem(16);
  }

  .required {
    div {
      color: $primaryRed;
    }
    span:before {
      display: inline-block;
      margin-right: toRem(4);
      color: $primaryRed;
      line-height: 1;
      content: '*';
    }
  }
}
