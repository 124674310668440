@import '~variables';
@import '~mixins';

.filter-bar {
  display: flex;
  align-items: flex-end;
  gap: toRem(8);
  margin-left: auto;
}

h5.filter-bar__title {
  margin-bottom: 0;
}

.filter-bar__close {
  color: $primaryBlueLightest;
  transition: 0.2s;
}

.filter-bar__button {
  padding: toRem(2) toRem(4);
  font-size: toRem(12);
  display: flex;
  align-items: center;
  background: $primaryBlueNew;
  border: toRem(1) solid $primaryBlueLightest;
  border-radius: $primaryRadius;
  color: $primaryWhite;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    border-color: $priorityRed;
    color: $priorityRed;
    background: none;

    .filter-bar__close {
      color: $priorityRed;
    }
  }
}
