@import '~variables';
@import '~mixins';

.tab-title {
  display: flex;
  margin-top: toRem(15);
  margin-bottom: toRem(10);

  &__name {
    font-size: toRem(20);
    font-weight: 600;
  }
}
