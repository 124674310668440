@import '~mixins';
@import '~variables';
@import '~mediaQueries';

.create-account-step {
  position: relative;
}

.create-account-step__form > form > div:nth-child(3) > div {
  margin-left: 0;
}