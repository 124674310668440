@import '~variables';
@import '~mixins';

.upload {
  .ant-upload-list-item {
    &:hover .ant-upload-list-item-info {
      background: 0 !important;
      border: 0 !important;
    }
    .ant-btn {
      background: 0 !important;
      border: 0 !important;
    }
  }
}
