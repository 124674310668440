@import '~variables';
@import '~mixins';

.location-info {
  text-align: center;
  padding: toRem(5);
  border: $grayBorder;
  border-radius: toRem(4);
  margin-bottom: toRem(20);
  &__title {
    font-weight: 600;
  }
}
