@import 'variables-colors';

/*
  BREAKPOINTS
  These values should be used in pixels as they are used for media queries
*/
$wideScreen: 1440;
// $largeScreen: 1240;
$midLargeScreen: 1100;
$fullScreen: 1024;
$mediumScreen: 960;
$tabletScreen: 768;
$tabletScreen_new: 720;
$modernMobileScreen: 575;
$classicMobileScreen: 375;
$oldSchoolMobileScreen: 320;

/* FONTS */
$defaultFont: 'Inter', sans-serif;
$primaryFont: 'Rubik', sans-serif;
/* HEADER */
$headerHeight: 60;

/* COLORS */
$priorityRed: #f5222d;
$priorityOrange: #fa8c16;
$priorityYellow: #fadb14;
$priorityGreen: #52c41a;
$priorityCyan: #13c2c2;
$primaryGrayDark: #595959;

:export {
  priorityRed: $priorityRed;
  priorityOrange: $priorityOrange;
  priorityYellow: $priorityYellow;
  priorityGreen: $priorityGreen;
  priorityCyan: $priorityCyan;
  // largeScreen: $largeScreen; adding a comment
  modernMobileScreen: $modernMobileScreen;
  fullScreen: $fullScreen;
  mediumScreen: $mediumScreen;
  closed: $primaryGrayDark;
}
