@import '~variables';
@import '~mixins';

.paper {
  padding: toRem(20);
  background: $primaryWhite;
  border-radius: $largeBorderRadius;
  box-shadow: $primaryBoxShadow;

  &--tab {
    padding-top: toRem(40);
    padding-bottom: toRem(70);
    background: rgba($primaryGray, 0.8);
  }

  &--form {
    padding-top: toRem(40);
    padding-bottom: toRem(70);
  }

  &--clickable {
    cursor: pointer;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      box-shadow: $buttonBoxShadow;
    }
  }
}
