@import '~variables';
@import '~mixins';

.state-label {
  padding: toRem(8);
  border-radius: toRem(4);
  font-weight: 800;
  display: inline-block;
  &--closed {
    color: $secondaryTextColor;
    background-color: rgba($secondaryTextColor, 0.1);
  }

  &--resolved {
    color: $priorityGreen;
    background-color: rgba($priorityGreen, 0.1);
  }

  &--cancelled {
    color: $priorityRed;
    background-color: rgba($priorityRed, 0.1);
  }
}
