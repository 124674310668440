@import '~variables';
@import '~mixins';

.incident-progress-bar {
  display: flex;

  &__progress-number {
    font-size: toRem(16);
    font-weight: 500;
    margin-left: toRem(5);
    color: $colorFontDefault;
    white-space: nowrap;
  }

  &__incident-status {
    color: $colorFontDefault;
    font-weight: 500;
    margin-right: toRem(10);
  }
  &__content {
    width: toRem(200);
    flex-grow: 1;
    display: flex;
    padding-right: toRem(5);

    div:first-child {
      width: 100%;
    }

    .ant-progress-inner {
      background-color: $primaryGrayLightest;
    }
  }
  &__tooltip {
    padding: toRem(10);
  }

  &__number-container {
    width: toRem(120);
    display: inline-block;
  }
}
