@import '~mixins';
@import '~mediaQueries';

.notes-comment-form__file-attaching {
  margin-bottom: toRem(10);
  display: flex;
  gap: toRem(10);
  flex-flow: wrap;

  .button.ant-btn {
    padding-right: toRem(8);
    padding-left: toRem(8);
    font-size: toRem(14);

    @include respond-above(xs) {
      padding-right: toRem(16);
      padding-left: toRem(16);
      font-size: toRem(16);
    }
  }
}
