@import '~variables';
@import '~mixins';

$primaryHover: #f6901c;
$primaryFocus: #fa8201;
$primaryActive: #e07604;
$primaryDisabled: #fcc58a;

$secondaryDisabled: #c1ccf9;

@mixin hover-styles {
  &:hover {
    @content;
  }
}

@mixin active-styles {
  &:active {
    @content;
  }
}

@mixin focus-styles {
  &:focus {
    @content;
  }
}

@mixin disabled-styles {
  &-disabled,
  &.disabled,
  &[disabled],
  &-disabled:hover,
  &.disabled:hover,
  &[disabled]:hover,
  &-disabled:focus,
  &.disabled:focus,
  &[disabled]:focus,
  &-disabled:active,
  &.disabled:active,
  &[disabled]:active,
  &-disabled.active,
  &.disabled.active,
  &[disabled].active {
    cursor: not-allowed;
    @content;
  }
}

.button {
  &.ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: toRem(14);
    border-width: toRem(1);
    min-width: toRem(98);
    border-radius: toRem(4);
    padding: toRem(10) toRem(16);
    height: toRem(39);

    & > .anticon + span,
    & > span + .anticon {
      margin-left: toRem(5.5);
    }

    &.ant-btn-primary {
      color: $primaryWhite;
      border-width: toRem(1);
      border-style: solid;
      border-color: $primaryHover;
      background-color: $primaryOrange;

      @include hover-styles {
        box-shadow: $buttonBoxShadow;
        background-color: $primaryHover;
        border-color: $primaryHover;
        color: $primaryWhite;
      }

      @include focus-styles {
        background-color: $primaryFocus;
        box-shadow: none;
        color: $primaryWhite;
        border-color: $primaryHover;
      }

      @include disabled-styles {
        color: $primaryWhite;
        border-color: transparent;
        background-color: $primaryDisabled;
        pointer-events: none;

        @include hover-styles {
          box-shadow: none;
        }
      }
    }

    color: $primaryBlueNew;
    border-color: $primaryBlueLightest;
    @include hover-styles {
      background-color: $primaryBlueNew;
      border-color: $primaryBlueNew;
      box-shadow: none;
      color: $primaryWhite;
    }

    @include active-styles {
      background-color: $primaryBlueNew;
      border-color: $primaryBlueNew;
      box-shadow: none;
      color: $primaryWhite;
    }

    @include focus-styles {
      border-color: $primaryBlueNew;
      color: $primaryBlueNew;
      background-color: $primaryWhite;
    }

    @include disabled-styles {
      color: $secondaryDisabled;
      background-color: $primaryWhite;
      pointer-events: none;
      border-color: $primaryGrayLightest;

      @include hover-styles {
        box-shadow: none;
      }
    }
  }

  &__title {
    &:first-letter {
      text-transform: uppercase;
    }
  }

  &.btn-sm {
    height: toRem(26);
    font-size: toRem(11);
    min-width: auto;
    span {
      font-weight: 800;
    }
  }

  &.btn-preview {
    border: toRem(1) solid $primaryBlueLightest;

    @include hover-styles {
      background-color: $primaryBlueNew;
      border-color: $primaryBlueNew;
      box-shadow: none;
      color: $primaryWhite;
    }
  }

  &.btn-download {
    background-color: $primaryOrange;
    color: $primaryWhite;
    border-color: $primaryHover;

    @include hover-styles {
      box-shadow: 0 toRem(2) toRem(8) $colorShadowDefault;
      background-color: $primaryHover;
      border-color: $primaryHover;
      color: $primaryWhite;
    }

    @include focus-styles {
      background-color: $primaryFocus;
      box-shadow: none;
      color: $primaryWhite;
      border-color: $primaryHover;
    }
  }
}
