@import '~variables';
@import '~mixins';

.SVR {
  &__column {
    height: 100%;
  }
}

.SVR.ant-collapse-item {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .ant-descriptions {
    .ant-collapse > .ant-collapse-item {
      border-bottom: 0;
    }
    .ant-descriptions-item-content {
      padding: toRem(10) 0 toRem(10) toRem(10);
      width: 100%;
      border-left: $grayBorder;
    }

    .ant-descriptions-item-label {
      width: toRem(300);
      padding: toRem(10);
    }

    span {
      font-size: toRem(12);
    }
    tr {
      display: flex;
      flex-direction: column;
      &:last-child {
        td {
          border-bottom: 0;
        }
      }

      td {
        display: flex;
        padding-bottom: 0;
        border-bottom: $grayBorder;
      }
    }
  }
}
