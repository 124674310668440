@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.incident-k-request {
  &__accept-reject {
    display: flex;
    justify-content: flex-end;
    margin-bottom: toRem(25);

    @include respond-above(md) {
      margin-bottom: toRem(20);
    }

    & button:first-child {
      margin-right: toRem(8);
    }
  }
}

.incident-k-request-btns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: toRem(8);

  & .button {
    padding-right: toRem(8);
    padding-left: toRem(8);

    @include respond-above(sm) {
      padding-right: toRem(16);
      padding-left: toRem(16);
    }
  }

  &__on-hold-reason-btn.ant-btn.button {
    font-size: toRem(11.2);
    border: toRem(1) solid $primaryBlueLightest !important;
    margin-right: toRem(10);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: toRem(68);
    border-radius: toRem(5);
    text-align: center;
    padding: 0 toRem(5);
    background: 0 !important;
    text-transform: none;
    cursor: default;
    box-shadow: none;
    &:hover {
      background: 0;
      color: $primaryBlueNew;
    }
  }
}
