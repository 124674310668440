@import '~mixins';
@import '~variables';
@import '~mediaQueries';

.invite-form {
  .form__spinner {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .col-flex {
    width: auto;
    display: block;

    .ant-input-number {
      width: 100%;
    }

    @include respond-above(md) {
      display: flex;

      .form-item-wrapper {
        width: calc(100% - 32px / 3);
      }

      .form-item-wrapper:nth-child(2) {
        margin: 0 toRem(16);
      }
    }
  }

  .ant-switch {
    width: toRem(40);
    background-color: $primaryGrayLightest;

    &.ant-switch-checked {
      background-color: $primaryGreen;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.invite-form__header {
  font-size: toRem(14);
  font-weight: 800;
  margin-top: toRem(24);
  margin-bottom: toRem(16);
}

.invite-form .collapse-wrapper {
  border: none;
}

.invite-form__checkbox {
  margin-top: toRem(26);

  label {
    font-size: toRem(14);

    &:after {
      display: none;
    }
  }
  .ant-row.ant-form-item {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    .ant-form-item-control {
      margin-right: toRem(9);
    }
  }
}
