@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.ant-modal {
  &.modal-wrapper {
    .ant-modal-content {
      width: -webkit-fill-available;
      border-radius: $primaryRadius;
      box-shadow: $primaryBoxShadow;
      padding: toRem(36) toRem(59) toRem(32) toRem(59);
      margin: 0 auto;
    }

    &.no-background {
      .ant-modal-mask {
        background: 0;
      }
    }

    .ant-modal-footer {
      border: 0;
      display: flex;
      justify-content: center;
      padding: toRem(16) 0 0 0;
    }
    .ant-modal-body {
      display: flex;
      align-items: center;
      padding: 0;
      font-size: toRem(16);
      flex-direction: column;
      div {
        white-space: break-spaces;
      }
    }

    .ant-modal-centered .ant-modal {
      text-align: center;
    }
  }
}

.modal__icon svg {
  height: toRem(24);
}

.ant-modal.modal-wrapper {
  width: calc(100% - #{toRem(20)}) !important;
}

.modal-wrapper .ant-modal-header {
  margin-bottom: toRem(24);
}

.modal-icon {
  font-size: toRem(40) !important;
}

.modal__content {
  width: -webkit-fill-available;
}

.modal__spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  backdrop-filter: $filter;
}

.warning {
  color: $priorityOrange;
}

.error {
  color: $priorityRed;
}

.info {
  color: $priorityCyan;
}

.success {
  color: $priorityGreen;
}

@include respond-above(xs) {
  .ant-modal.modal-wrapper {
    width: calc(100% - #{toRem(60)}) !important;
  }
}

@include respond-above(md) {
  .ant-modal.modal-wrapper {
    width: 50% !important;
    max-width: toRem(750) !important;

    &--full-width {
      width: calc(100% - #{toRem(80)}) !important;
      max-width: calc(#{map-get($breakpoints, xl)} - #{toRem(80)}) !important;
    }
  }
}
