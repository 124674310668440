@import '~variables';
@import '~mixins';

.data-table {
  overflow: hidden;
  //border-radius: $primaryRadius;
  //box-shadow: $primaryBoxShadow;
  //background: $primaryWhite;
  //padding: 0 toRem(16) toRem(16);

  .ant-empty-normal {
    margin: toRem(32) auto;
  }
  &__skeleton {
    overflow: hidden;
  }

  &__skeleton-item {
    border-radius: toRem(4);
  }

  &__custom-pager {
    margin-top: toRem(32);
  }

  &-title {
    display: flex;
    justify-content: flex-end;
  }

  .ant-table {
    margin-top: toRem(20);

    thead.ant-table-thead > tr,
    tbody.ant-table-tbody > tr {
      height: toRem(40);
      font-size: toRem(14);
    }

    tbody.ant-table-tbody tr.ant-table-row:last-child .ant-table-cell {
      border: none;
    }

    .ant-table-title {
      padding: 0 0 toRem(16) 0;
    }

    .ant-table-tbody > tr > td {
      border-bottom: $grayBorder;
    }

    thead.ant-table-thead > tr {
      height: toRem(32);
      & > th {
        background: 0;
        color: $colorFontDefault;
        font-size: toRem(12);
        padding: toRem(8) toRem(16) toRem(8) toRem(8);

        div.sort {
          display: flex;
          cursor: pointer;
          &:hover .anticon:last-child {
            color: $colorFontDefault;
          }
        }

        .anticon:last-child {
          transition: all 0.3s;
          display: flex;
          align-items: center;
          justify-content: center;
          width: toRem(32);
          color: $primaryGrayDark;
        }
      }
    }

    .anticon-close {
      font-size: toRem(18);
      color: $primaryRed;
      width: 100%;
      text-align: center;
    }
    .anticon-check {
      font-size: toRem(18);
      color: $primaryGreen;
      width: 100%;
      text-align: center;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: $colorBackgroundBlue;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      overflow-wrap: normal;
    }

    &.ant-table-bordered {
      & > .ant-table-container {
        border: none;
      }
    }

    tr > td {
      color: $colorFontDefault;
      border-right: none !important;
    }
  }

  .ant-table-title {
    padding: 0 0 toRem(16) 0;
  }

  .ant-table-wrapper {
    padding: 0;
    @media (min-width: 768px) {
      padding: 0 toRem(24);
    }
  }

  thead.ant-table-thead > tr {
    height: toRem(40);

    & > th {
      background: 0;
      color: $primaryGrayDark;
      font-size: toRem(12);
      padding: toRem(8) toRem(10);
      border-right: none !important;

      div.sort {
        display: flex;
        cursor: pointer;

        &:hover .anticon:last-child {
          color: $colorFontDefault;
        }
      }

      .anticon:last-child {
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        width: toRem(32);
        color: $primaryGrayDark;
      }
    }
  }
}

.active-filter svg {
  color: $primaryBlue;
}

div.ant-table-filter-dropdown {
  padding: toRem(8);
  border-radius: toRem(6.5);
  &--width {
    width: toRem(264);
  }
  input {
    width: 100%;
    margin-right: toRem(8);
    margin-bottom: toRem(8);
  }
  .ant-select {
    width: 100%;
    margin-right: toRem(8);
    margin-bottom: toRem(8);
  }

  button.ant-btn-primary {
    background-color: $primaryYellow;
    border-color: $primaryYellow;
    &:hover {
      color: $colorFontDefault;
    }
  }
  button.ant-btn {
    margin-right: toRem(8);
    height: toRem(32);
    color: $colorFontDefault;
    padding: 0 toRem(10);
    border-width: toRem(1);
    border-radius: toRem(4);
    font-size: toRem(12);

    &:last-child {
      margin-right: 0;
    }
  }
}

.data-table .ant-table-content > table {
  width: -webkit-fill-available !important;
}

.ant-table-filter-trigger-container-open,
.ant-table-filter-trigger-container:hover,
.ant-table-thead
  th.ant-table-column-has-sorters:hover
  .ant-table-filter-trigger-container:hover {
  background: none !important;
}

.ant-table-cell {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: toRem(47);
  > div > div {
    width: max-content;
  }
}
