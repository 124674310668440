@import '~mixins';
@import '~variables';
@import "~mediaQueries";

.create-account-and-organization {
  padding: toRem(20) toRem(40);
}

.create-account-and-organization__profile-wrapper {
  margin-right: toRem(10);
  margin-left: toRem(10);
  max-width: toRem(1080);
}

.create-account-and-organization__steps {
  margin-bottom: toRem(40);
}

.create-account-and-organization > form > div:nth-child(3) > div {
  margin-left: 0;
}

@include respond-above(xs) {
  .create-account-and-organization__profile-wrapper {
    margin-right: toRem(20);
    margin-left: toRem(20);
  }
}

@include respond-above(md) {
  .create-account-and-organization__profile-wrapper {
    margin-right: toRem(40);
    margin-left: toRem(40);
  }
}
