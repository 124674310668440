@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.purchase-price {
  margin-bottom: toRem(-10);
  color: $primaryButtonOrange;
  text-transform: lowercase;
}

.purchase-price__cycle {
  color: $primaryGrayLight;
  font-size: toRem(10);
}

@include respond-above(xl) {
  .purchase-price__cycle {
    color: $primaryGrayLight;
    font-size: toRem(11);
  }
}
