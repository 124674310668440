@import '~mixins';
@import '~variables';

.finance-dashboard {
  position: relative;
  margin-bottom: toRem(48);
}

.finance-dashboard__tile {
  width: 100%;
  display: flex;
  align-items: flex-end;
}
