@import '~mixins';
@import '~variables';

.statistics-card {
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
}

.statistics-card__skeleton {
  width: 100%;
  height: 100%;
}

.statistics-card__title {
  margin-bottom: toRem(8);
  color: $colorFontDefault;
}

.statistics-card__growth {
  margin-bottom: toRem(20);
  font-weight: 400;
  font-size: toRem(14);
  letter-spacing: toRem(0.15);
  color: $colorFontDefault;
}

.statistics-card__growth-total {
  font-weight: 600;
  color: $colorFontDefault;
  font-size: toRem(14);
  letter-spacing: toRem(0.15);
}

.statistics-card__tile-list {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
}

.statistics-card__tile {
  display: flex;
  flex-flow: wrap;
}

.statistics-card__icon-box {
  position: relative;
  display: flex;
  margin-right: toRem(8);
  width: toRem(44);
  height: toRem(44);
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
  user-select: none;
  border-radius: $largeBorderRadius;
  color: $primaryGray;
  box-shadow: rgb(58 53 65 / 42%) 0 4px 8px -4px;


  &--blue {
    background-color: $primaryBlue;
  }

  &--green{
    background-color: $primaryGreen;
  }

  &--yellow{
    background-color: $primaryYellow;
  }
}

.statistics-card__icon-wrapper {
  line-height: 0;
}


.statistics-card__icon-wrapper svg {
  width: toRem(24);
  height: toRem(24);
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: $primaryWhite;
}

.statistics-card__icon-title {
  font-weight: 400;
  font-size: toRem(12);
  letter-spacing: toRem(0.4);
  color: $colorFontDefault;
}

.statistics-card__count {
  font-weight: 500;
  font-size: toRem(20);
  line-height: 1.6;
  letter-spacing: toRem(0.15);
  color: $colorFontDefault;
}
