@import '~variables';
@import '~mixins';

.skeleton-item {
  position: relative;
  overflow: hidden;
  border-radius: toRem(2);
  background: $colorBackgroundGrayDark;

  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    animation: wave 1s linear 0.5s infinite;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba($primaryWhite, 0.4), transparent);
  }

  &--circle {
    aspect-ratio: 1/1;
    border-radius: 50%;
  }
}

@keyframes wave {
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

