@import '~variables';
@import '~mixins';

.ellipsis-text-hint {
    max-width: 100%;
    max-height: 100%;
    min-width: 0;
    display: flex;
    align-items: center;

    &__item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
