@import '~variables';
@import '~mixins';

.table-columns-customizer {
  &__sortable-items {
    z-index: 10;
  }

  &__trigger-button {
    &--active {
      &.button.ant-btn.ant-btn-text {
        color: $colorFontDefault;
        background: $primaryGrayLightest;
      }
    }
  }
  &__container {
    width: toRem(300);
    height: toRem(425);
    background: $primaryWhite;
    border: $grayBorder;
    border-radius: toRem(4);

    &--filtered {
      .table-columns-customizer__drag-handle-wrapper {
        cursor: no-drop;
      }
      .table-columns-customizer__drag-handle {
        pointer-events: none;
        color: $colorFontDefaultDisabled;
      }
    }

    ul {
      padding-left: toRem(0);
    }
  }

  &__body {
    margin-top: toRem(60);
    height: toRem(360);
    overflow-y: auto;
    .table-columns-customizer__item {
      padding-left: toRem(12);
      &:hover {
        background-color: $primaryGray;
      }
    }
  }

  &__input-container {
    height: toRem(60);
    padding: toRem(12);
    border-bottom: $grayBorder;
    margin-bottom: toRem(12);
    position: absolute;
    background-color: $primaryWhite;
  }

  &__input {
    &.ant-input-affix-wrapper {
      border: $grayBorder;
      border-radius: toRem(4);
    }
  }

  &__search-icon {
    color: $primaryGrayDark;
  }

  &__item {
    height: toRem(40);
    font-size: toRem(14);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__no-data {
    display: flex;
    justify-content: center;
    color: $primaryGrayDark;
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__drag-handle {
    cursor: grab;
    margin-right: toRem(12);
    color: $primaryGrayDark;
    display: flex;
    align-items: center;
  }

  &__drag-handle-wrapper {
    padding-top: toRem(7);
  }

  &__switch {
    &.ant-switch {
      margin-right: toRem(10);
    }
    &--checked.ant-switch-checked {
      background: $priorityGreen;
    }
  }
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  color: rgba(0, 0, 0, 0.85);
}
