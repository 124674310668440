@import '~variables';
@import '~mixins';

.credit-system__top {
  font-size: toRem(18);
  margin: 0 auto;
  text-align: center;
  width: toRem(250);
  max-width: 100%;
  span {
    display: inline-block;
    margin-right: toRem(3);
    &:nth-of-type(1) {
      font-size: toRem(15);
    }
    &:nth-of-type(2) {
      margin-right: toRem(10);
    }
  &__header {
    background-color: transparent;
    padding: toRem(13) 0;
    border: $grayBorder;
    display: flex;
    justify-content: center;
    align-items: center;
    }
  }
}

.credit-system .col_margin {
  margin-left: toRem(20);
}

.credit-system .paper {
  background: transparent;
  border: none;
  box-shadow: none;
}
