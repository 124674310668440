@import '~variables';
@import '~mixins';

.incident-detail-mobile {
  .ant-tabs-nav-list {
    justify-content: space-around;
    width: 100%;
  }

  .ant-tabs-tab {
    font-size: toRem(9);
    margin: 0 toRem(8) 0 toRem(1);
    .anticon {
      margin: 0;
      font-size: toRem(20);
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 toRem(8) 0 toRem(1);
    font-size: toRem(9);
  }
}

.incident-detail-mobile__tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0;
}
