@import '~mixins';

.identities {
  &__roles {
    font-size: toRem(12);
  }
}

.itsm-identities-role {
  .ant-drawer-title {
    padding: 0;
  }
  .ant-transfer {
    padding-top: toRem(4);
    width: 100%;
  }
  .ant-input-number {
    width: 100%;
  }
  .drawer-footer.ant-layout-footer {
    padding: toRem(15) 0 0 0;
    position: relative;
  }
}

.itsm-identities-role .ant-transfer .ant-transfer-list {
  width: 40%;
  height: toRem(300);
}

.itsm-identities-role .ant-transfer .ant-transfer-operation {
  width: 20%;
  padding: toRem(8);
  display: inline-grid;
  margin: 0;
}

.itsm-identities-role .ant-transfer .ant-transfer-operation .ant-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.itsm-identities-role
  .ant-transfer
  .ant-transfer-operation
  .ant-btn:first-child {
  flex-direction: row-reverse;
}

.itsm-identities-role .ant-form-item {
  margin-bottom: toRem(20);
}

.itsm-identities-role .ant-form-item .ant-form-item-label {
  font-size: toRem(15);
}

.itsm-identities-role .ant-form-item .ant-form-item-control {
  line-height: toRem(20);
}
