@import '~mixins';
@import '~variables';

$i: 0;
@while $i <= 30 {
  .mt-#{$i} {
    margin-top: toRem($i);
  }
  .mb-#{$i} {
    margin-bottom: toRem($i);
  }
  .ml-#{$i} {
    margin-left: toRem($i);
  }
  .mr-#{$i} {
    margin-right: toRem($i);
  }
  .pt-#{$i} {
    padding-top: toRem($i);
  }
  .pb-#{$i} {
    padding-bottom: toRem($i);
  }
  .pl-#{$i} {
    padding-left: toRem($i);
  }
  .pr-#{$i} {
    padding-right: toRem($i) !important;
  }
  .fs-#{$i} {
    font-size: toRem($i);
  }
  $i: $i + 1;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.flex {
  display: flex;
  &--spaceBetween {
    justify-content: space-between;
  }
  &--spaceAround {
    justify-content: space-around;
  }
  &--alignCenter {
    align-items: center;
  }
  &--justifyCenter {
    justify-content: center;
  }
  &--justifyEnd {
    justify-content: flex-end;
  }
  &--column {
    flex-direction: column;
  }
  &--wrap {
    flex-wrap: wrap;
  }
  &--alignStart {
    align-items: flex-start;
  }

  &--alignEnd {
    align-items: flex-end;
  }
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.full-height {
  height: 100% !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
  width: 100%;
  clear: both;
}

.pointer-cursor {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.nowrap {
  white-space: nowrap;
}

.link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  display: inline;
  margin: 0;
  padding: 0;

  &:hover,
  .link:focus {
    text-decoration: none;
  }
  &--blue {
    color: $primaryBlue;
  }
}

.fixed {
  position: fixed;
}

.capitalize {
  &--words {
    text-transform: capitalize;
  }
  &--first::first-letter {
    text-transform: uppercase;
  }
  &--all {
    text-transform: uppercase;
  }
}
