@import '~mixins';
@import '~variables';

.apex-chart-skeleton {
  padding: toRem(20);
  height: 100%;
  width: 100%;
}

.apex-chart-skeleton__title {
  margin-bottom: toRem(20);
}
