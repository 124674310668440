@import '~mixins';

.itsm-chatroom {
  margin-top: toRem(30);

  .ant-card-body {
    padding: toRem(5) toRem(20);
    .ant-list-item-meta {
      display: flex;
      flex: 1 1;
      align-items: flex-start;
      max-width: 100%;
    }
    .ant-list-item-meta-avatar {
      margin-right: toRem(16);
    }
    .ant-list-item-meta-title {
      word-break: break-word;
      margin-bottom: toRem(4);
      color: rgba(0, 0, 0, 0.85);
      font-size: toRem(14);
      line-height: 1.5715;
      p {
        margin-bottom: 0;
      }
    }
    .ant-list-item-meta-description {
      color: rgba(0, 0, 0, 0.45);
      font-size: toRem(14);
      line-height: 1.5715;
    }
  }

  &__avatar {
    vertical-align: middle;
  }

  &__title {
    display: flex;
    justify-content: space-between;
  }

  &__read-by {
    font-weight: 400;
    cursor: pointer;
    font-size: toRem(12);
    white-space: nowrap;
  }

  &__read-by-details {
    text-align: center;
    min-width: toRem(120);
    font-size: toRem(11);
  }

  &__skeleton {
    width: 100%;
  }
}
