@function toRem($size) {
  @return #{calc(#{$size} / #{$root-font-size} * 1rem)};
}

@mixin input() {
  height: toRem(56);
  border: toRem(1) solid $primaryBlueLightest;
  border-radius: $primaryRadius !important;
  box-shadow: none;
  background-color: $primaryWhite;

  &:hover,
  &:focus,
  &:active {
    border-color: $primaryBlue;
    box-shadow: none;
  }
}

$root-font-size: 16; // the default font-size in browser
$input-radius: toRem(5);
$filter: blur(toRem(3));

@mixin loading {
  background: linear-gradient(
    100deg,
    $primaryBlue 30%,
    $primaryGray 50%,
    $primaryBlue 70%
  );
  background-size: 400%;
  animation: loading 3s ease-in-out infinite;

  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0 50%;
    }
  }
}
