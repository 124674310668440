@import '~variables';
@import '~mixins';

.ant-form-item-label label:after {
  content: '';
  margin: 0;
}

.ant-time-picker-panel-select {
  max-height: toRem(130);
}

.ant-transfer-list {
  .ant-input {
    background-color: $primaryGray;
    border: 0;

    &:focus,
    &:hover {
      box-shadow: none;
      border: 0;
    }
  }
}
.ant-input,
.ant-select,
.ant-form,
.ant-card {
  color: $colorFontDefault;
}

.ant-input-number.ant-input-number-disabled {
  background-color: $inputDisabledGrey;
  color: $primaryBlack;
  opacity: 0.5 !important;
  border-color: $inputDisabledGrey;
  cursor: not-allowed;
}

.ant-input-group-addon {
  color: $primaryGrayDark;
}

.ant-form-horizontal .ant-form-item-control {
  flex: none;
}

.ant-row.ant-form-item {
  display: block;
}
// .ant-col {
//   width: 100%;
// }

.col-flex {
  &--two,
  &--width,
  .form-item-wrapper {
    width: 100%;
  }
}

.ant-select-selection-placeholder {
  align-self: center;
}
.ant-input-number {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 992px) {
  .col-flex {
    display: flex;
    .form-item-wrapper {
      width: calc(100% / 3);
    }
    .form-item-wrapper:nth-child(2) {
      margin: 0 toRem(30);
    }

    &--width {
      width: 100%;
    }

    &--two {
      display: flex;
      width: 100%;
      .form-item-wrapper {
        width: calc(100% - 100px / 2);
      }

      .form-item-wrapper:nth-child(1) {
        margin-right: toRem(50);
      }
      .form-item-wrapper:nth-child(2) {
        margin: 0;
      }
    }
  }
}
