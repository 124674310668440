@import '~variables';

html {
  font-size: 100%;
}

.test {
  border: 1px dashed red;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix on mobile */
  min-height: -webkit-fill-available;
  margin: 0;
  padding: 0;
  font-family: $defaultFont;
  line-height: 1.5715;
  background-color: $primaryGray;
  color: $colorFontDefault;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}
