@import '~variables';
@import '~mixins';

.itsm-text-content {
  opacity: 1;
  line-height: 1.5;
  padding: toRem(10);
  word-wrap: break-word;

  &--text-area {
    resize: both;
    overflow: auto;
    border: $grayBorder;
    border-radius: toRem(4);
    min-height: toRem(150);
    cursor: not-allowed;
    &--disabled {
      background-color: $inputDisabledGrey;
      border: 0;
      opacity: 0.5;
    }
  }
  p {
    color: $colorFontDefault;
  }
}
