@import '~variables';
@import '~mixins';

.details-card {
  width: 100%;
  min-width: toRem(290);
  padding: toRem(6) toRem(12);
  margin-top: toRem(12);
  border: $grayBorder;
  box-shadow: 0 toRem(2) toRem(4) toRem(-2) $colorShadowDefault;
  border-radius: toRem(4);

  &__skeleton {
    padding: 0;
  }

  &__header {
    font-size: toRem(20);
  }

  &__subheader {
    line-height: toRem(20);
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    margin-top: toRem(8);
  }

  &__detail-item {
    width: toRem(120);
    height: toRem(40);
    flex-grow: 1;
    margin-bottom: toRem(10);
    margin-right: toRem(12);

    &--wide {
      width: 100%;
    }
  }

  &__label {
    color: $primaryGrayDark;
    font-size: toRem(12);
  }
}
