@import '~variables';
@import '~mixins';

$size: 18;

.custom-check-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: toRem($size);
  max-width: toRem($size);
  min-width: toRem($size);
  height: toRem($size);
  background-color: $primaryGreenLight;
  color: $primaryWhite;
}
