@import '~mixins';
/*
  CAUTION.
  If you want change something here you should know exactly what you are doing. BE CAREFUL

  -----------------------------

  Main template for color naming.
  Please pay attention we have just only 5 types for one color

  {PREFIX}{COLOR}Darkest
  {PREFIX}{COLOR}Dark
  {PREFIX}{COLOR}
  {PREFIX}{COLOR}Light
  {PREFIX}{COLOR}Lightest


  {PREFIX} - for example 'primary', 'colorBackground'
  {COLOR} - name of color, for example 'Red', 'Blue'
*/

/*
  PRIMARY COLORS
  ---
  Palette colors.
  Don't change if you are not sure
*/
$primaryBlack: rgba(58, 53, 65);
$primaryBlackLight: #121212;
$primaryBlackTransparent: rgba(0, 0, 0, 0.06);

$primaryGrayDark: rgba($primaryBlack, 0.68);
$primaryGray: #f4f5fa;
$primaryGrayLight: #afafaf;
$primaryGreyLighter: #bdbdbd;
$primaryGrayLightest: #eee;

$primaryBlue: #1e90ff;
$primaryBlueLightest: #becbff;

$primaryYellow: #ffe000;
$primaryGreenLight: #2ac42e;
$primaryGreenLightest: rgba($primaryGreenLight, 0.85);
$primaryGreen: #65c466;
$primaryRed: #ff5757;
$primaryRedLight: rgba($primaryRed, 0.85);
$primaryWhite: #fff;
$primaryBorderColor: #b3b3bf;

// Border radius
$primaryRadius: $input-radius;
$largeBorderRadius: toRem(6);

$primaryBlueNew: #2f54eb;
$secondaryTextColor: #6e6b7b;

//Redesign Buttons colors

$primaryButtonOrange: #fe9c01;

$primaryOrange: #fa8c16;
$primaryOrangeLight: #ffcf5c;

//Disabled inputs
$inputDisabledGrey: #dddddd;
$inputDisabledGreyHover: #d1cece;

/*
  PRIORITY GROUP
  ---
  Special color group for importance indicators.
  Don't change if you are not sure
*/
$priorityRed: #e62828;
$priorityOrange: #f6901c;
$priorityYellow: #f6c82e;
$priorityGreen: #52c41a;
$priorityCyan: #2ed8e6;
$inputBlue: #becbff;

//GRADIENTS
$primaryOrangeGradient: linear-gradient(  98deg, $priorityOrange, $primaryOrangeLight 94%);

/*
  CUSTOM COLORS
  ---
  Here you can add you own variables.
  Please use colors from the palette
*/
$colorBackgroundGrayDark: rgba($primaryGrayLightest, 0.6);
$colorBackgroundGray: rgba($primaryGrayLightest, 0.4);
$colorBackgroundGreen: rgba($primaryGreenLight, 0.12);
$colorBackgroundBlue: rgba($primaryBlue, 0.1);

/* Default colors (main naming template doesn't use here) */
$colorFontDefault: rgba(58, 53, 65, 0.87);
$colorFontDefaultLight: rgba(58, 53, 65, 0.38);
$colorFontDefaultDisabled: rgba($primaryBlack, 0.25);
$colorBorderDefault: $primaryGrayLightest;
$colorShadowDefault: rgba($primaryBlack, 0.12);

/*Borders  */
$grayBorder: toRem(1) solid $colorBorderDefault;
$blueBorder: toRem(2) solid $primaryBlueNew;
$inputBorder: toRem(1) solid rgb(190, 203, 255);

// Box shadow
$primaryBoxShadow: rgba($primaryBlack, 0.1) 0px toRem(2) toRem(10) 0px;
$buttonBoxShadow: rgb(58 53 65 / 42%) 0px 4px 8px -4px;
