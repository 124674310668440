@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?75931909');
  src: url('../font/fontello.eot?75931909#iefix') format('embedded-opentype'),
    url('../font/fontello.woff2?75931909') format('woff2'),
    url('../font/fontello.woff?75931909') format('woff'),
    url('../font/fontello.ttf?75931909') format('truetype'),
    url('../font/fontello.svg?75931909#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?75931909#fontello') format('svg');
  }
}
*/
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-logout:before {
  content: '\e800';
} /* '' */
.icon-setting:before {
  content: '\e801';
} /* '' */
.icon-plus:before {
  content: '\e802';
} /* '' */
.icon-minus:before {
  content: '\e803';
} /* '' */
.icon-undo:before {
  content: '\e804';
} /* '' */
.icon-close:before {
  content: '\e805';
} /* '' */
.icon-trash:before {
  content: '\e806';
} /* '' */
.icon-search:before {
  content: '\e807';
} /* '' */
.icon-edit:before {
  content: '\e808';
} /* '' */
.icon-check:before {
  content: '\e809';
} /* '' */
.icon-download:before {
  content: '\e80a';
} /* '' */
.icon-upload:before {
  content: '\e80b';
} /* '' */
.icon-warning:before {
  content: '\e80c';
} /* '' */
.icon-clock:before {
  content: '\e80d';
} /* '' */
.icon-location:before {
  content: '\e80e';
} /* '' */
.icon-info:before {
  content: '\e80f';
} /* '' */
.icon-filter:before {
  content: '\e810';
} /* '' */
.icon-loading:before {
  content: '\e811';
} /* '' */
.icon-field-time:before {
  content: '\e812';
} /* '' */
.icon-copy:before {
  content: '\e813';
} /* '' */
.icon-left:before {
  content: '\e814';
} /* '' */
.icon-right:before {
  content: '\e815';
} /* '' */
.icon-history:before {
  content: '\e816';
} /* '' */
.icon-snippet:before {
  content: '\e817';
} /* '' */
.icon-sort-asc:before {
  content: '\e818';
} /* '' */
.icon-sort-desc:before {
  content: '\e819';
} /* '' */
.icon-attachment:before {
  content: '\e81a';
} /* '' */
.icon-at:before {
  content: '\e81b';
} /* '' */
.icon-eye:before {
  content: '\e81c';
} /* '' */
.icon-project:before {
  content: '\e81d';
} /* '' */
.icon-share:before {
  content: '\e81e';
} /* '' */
.icon-unordered-list:before {
  content: '\e81f';
} /* '' */
.icon-pdf-file:before {
  content: '\e820';
} /* '' */
.icon-cloud-upload:before {
  content: '\e821';
} /* '' */
.icon-check-circle:before {
  content: '\e822';
} /* '' */
.icon-check-square:before {
  content: '\e823';
} /* '' */
.icon-close-circle:before {
  content: '\e824';
} /* '' */
.icon-close-square:before {
  content: '\e825';
} /* '' */
.icon-euro:before {
  content: '\e826';
} /* '' */
.icon-controls:before {
  content: '\e827';
} /* '' */
.icon-menu:before {
  content: '\e828';
} /* '' */
.icon-caret-left:before {
  content: '\e829';
} /* '' */
.icon-caret-right:before {
  content: '\e82a';
} /* '' */
.icon-down:before {
  content: '\e82b';
} /* '' */
.icon-up:before {
  content: '\e82c';
} /* '' */
.icon-flag:before {
  content: '\e82d';
} /* '' */
.icon-ellipsis:before {
  content: '\e82e';
} /* '' */
.icon-more:before {
  content: '\e82f';
} /* '' */
.icon-heartbeat:before {
  content: '\e830';
} /* '' */
.icon-mac:before {
  content: '\e831';
} /* '' */
.icon-car:before {
  content: '\e832';
} /* '' */
.icon-question:before {
  content: '\e833';
} /* '' */
.icon-battery:before {
  content: '\e834';
} /* '' */
.icon-bell:before {
  content: '\e835';
} /* '' */
.icon-building:before {
  content: '\e836';
} /* '' */
.icon-card:before {
  content: '\e837';
} /* '' */
.icon-braille-six-dots:before {
  content: '\e838';
} /* '' */
.icon-cloud:before {
  content: '\e839';
} /* '' */
.icon-code:before {
  content: '\e83a';
} /* '' */
.icon-pause-outline:before {
  content: '\e83b';
} /* '' */
.icon-email:before {
  content: '\e83c';
} /* '' */
.icon-warning-rounded:before {
  content: '\e83d';
} /* '' */
.icon-wrench:before {
  content: '\e83e';
} /* '' */
.icon-emailserver:before {
  content: '\e83f';
} /* '' */
.icon-folder:before {
  content: '\e840';
} /* '' */
.icon-headset:before {
  content: '\e841';
} /* '' */
.icon-keyboard:before {
  content: '\e842';
} /* '' */
.icon-linuxos:before {
  content: '\e843';
} /* '' */
.icon-mac-os:before {
  content: '\e844';
} /* '' */
.icon-target:before {
  content: '\e845';
} /* '' */
.icon-mouse:before {
  content: '\e846';
} /* '' */
.icon-notebook:before {
  content: '\e847';
} /* '' */
.icon-pcb:before {
  content: '\e848';
} /* '' */
.icon-phone:before {
  content: '\e849';
} /* '' */
.icon-powersupply:before {
  content: '\e84a';
} /* '' */
.icon-printer:before {
  content: '\e84b';
} /* '' */
.icon-sdcard:before {
  content: '\e84c';
} /* '' */
.icon-send:before {
  content: '\e84d';
} /* '' */
.icon-servers:before {
  content: '\e84e';
} /* '' */
.icon-file-empty:before {
  content: '\e84f';
} /* '' */
.icon-usb:before {
  content: '\e850';
} /* '' */
.icon-wifi:before {
  content: '\e851';
} /* '' */
.icon-sim:before {
  content: '\e852';
} /* '' */
.icon-computer:before {
  content: '\e853';
} /* '' */
.icon-filter-stripes:before {
  content: '\e854';
} /* '' */
.icon-lock:before {
  content: '\e855';
} /* '' */
.icon-directions:before {
  content: '\e856';
} /* '' */
.icon-person:before {
  content: '\e857';
} /* '' */
.icon-connection:before {
  content: '\e858';
} /* '' */
.icon-squares:before {
  content: '\e859';
} /* '' */
.icon-users:before {
  content: '\e85a';
} /* '' */
.icon-tetris-four:before {
  content: '\e85b';
} /* '' */
.icon-file-filled:before {
  content: '\e85c';
} /* '' */
.icon-price:before {
  content: '\e85d';
} /* '' */
.icon-briefcase:before {
  content: '\e85e';
} /* '' */
.icon-th-large:before {
  content: '\e85f';
} /* '' */
.icon-long-arrow-left:before {
  content: '\e860';
} /* '' */
.icon-drawer:before {
  content: '\e861';
} /* '' */
.icon-chart-bar-outline:before {
  content: '\e862';
} /* '' */
.icon-file-sealed:before {
  content: '\e863';
} /* '' */
.icon-emo-devil:before {
  content: '\e864';
} /* '' */
.icon-desktop:before {
  content: '\f108';
} /* '' */
.icon-windows:before {
  content: '\f17a';
} /* '' */
.icon-bank:before {
  content: '\f19c';
} /* '' */
.icon-file-excel:before {
  content: '\f1c3';
} /* '' */
.icon-user-o:before {
  content: '\f2c0';
} /* '' */
