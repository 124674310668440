@import '~variables';
@import '~mixins';

.my-accounts {
  &__delete-btn-table {
    color: $primaryRed;
    margin-right: toRem(10);
    &:hover {
      cursor: pointer;
    }
  }
  &__header {
    margin-top: toRem(24);
    justify-content: flex-start;

    &_bold {
      font-weight: 500;
      font-size: toRem(18) !important;
      margin-bottom: 0 !important;
    }
  }
  &__id-container {
    margin: 0;
  }
  &__edit-btn-table {
    &:hover {
      cursor: pointer;
    }
  }
}
