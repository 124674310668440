@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.landing-page {
  padding-right: toRem(24);
  padding-left: toRem(24);
}

.landing-page__header {
  padding: toRem(15) 0;
}

.landing-page__logo {
  height: toRem(30);
}

.landing-page__body {
  margin-top: toRem(120);
}

.landing-page__tooltip .ant-tooltip-inner {
  // doesn't work variables for --antd-arrow-background-color
  background: #2f54eb;
  color: $primaryWhite;
}

.landing-page__tooltip .ant-tooltip-arrow-content {
  // doesn't work variables
  --antd-arrow-background-color: #2f54eb;
}

.landing-page__tooltip-border {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: toRem(-8);
    bottom: toRem(-8);
    left: toRem(-8);
    right: toRem(-8);
    border: 2px solid $primaryBlueNew;
    border-radius: inherit;
  }
}

.landing-page__img {
  display: none;
  position: relative;
}

.landing-page__screen {
  z-index: 1;
}

.landing-page__mask {
  position: absolute;
  top: 0;
  left: 2%;
  width: 89%;
  z-index: -1;
}

@include respond-above(md) {
  .landing-page {
    padding-right: toRem(48);
    padding-left: toRem(148);
  }

  .landing-page__logo {
    height: toRem(41);
  }

  .landing-page__body {
    margin-top: toRem(96);
  }

  .landing-page__img {
    display: block;
  }
}
