@import '~variables';
@import '~mediaQueries';

.ant-layout-header {
  position: sticky;
  z-index: 1;
  top: 0;
  height: auto;
  padding: 0 toRem(50);
  background: $primaryGray;
  line-height: inherit;
}
.ant-layout-footer {
  &.footer {
    padding: toRem(24) toRem(16);
  }
}
.footer__blits-logo {
  height: toRem(30);
  width: toRem(100);
}
.footer__logo-text {
  color: $primaryGrayLight;
  font-size: toRem(13);
  margin-bottom: toRem(30);
}

.footer__copyright {
  color: $primaryGrayLight;
  font-size: toRem(14);
  margin: toRem(30) 0 toRem(20) 0;
  text-align: center;
}

.footer__products {
  font-size: toRem(14);
  margin-bottom: toRem(12);
  a {
    color: $primaryGrayLight;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
}
.footer .ant-input-group-addon {
  &:first-child {
    border-radius: toRem(5) 0 0 toRem(5);
  }
  &:last-child {
    border-radius: 0 toRem(5) toRem(5) 0;
    padding: 0 !important;
    line-height: 0;
  }
}

.footer .ant-input {
  border-left: none;
  border-right: none;
  height: toRem(35);
  &:focus {
    border-color: $primaryBorderColor;
    box-shadow: none;
  }
}
.footer .ant-input:hover {
  border-color: rgba($primaryBorderColor, 0.5);
}

.footer__mail-icon {
  height: toRem(25);
  width: toRem(25);
  background-color: #2f54eb;
  color: #fff;
  border-radius: $primaryRadius;
  margin: toRem(3);

  svg {
    margin: 20%;
  }
}
.footer__blits-social {
  border-radius: toRem(25);
}
.footer__container {
  display: flex;
  justify-content: center;
}
.mobile-purchase__buy-now {
  z-index: 2;
}

@include respond-above(sm) {
  .footer .ant-input-group-wrapper {
    width: 50%;
  }
}

@include respond-above(md) {
  .footer__container-row {
    max-width: map-get($breakpoints, md);
    width: 100%;
  }
}

@include respond-above(md) {
  .footer .ant-input-group-wrapper {
    width: 100%;
  }
}
@include respond-above(xl) {
  .footer__container-row {
    max-width: map-get($breakpoints, lg);
    width: 100%;
  }
}
