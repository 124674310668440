@import '~mixins';
@import '~variables';

.confirmation_block {
    text-align: center;
    background-color: $colorBackgroundBlue;
    border: toRem(1) solid rgba($primaryBlue, 0.5);
    padding: toRem(18);
    margin-bottom: toRem(15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-btn {
      height: toRem(32);
      padding: 0;
      min-width: toRem(104);
      max-width: toRem(104);
    }
  }