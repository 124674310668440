@import '~variables';
@import '~mixins';

.profile-identity-wrapper {
  width: toRem(790);
  margin: 0 auto;
  background-color: $primaryWhite;
  box-shadow: 0 toRem(4) toRem(15) rgba(47, 84, 235, 0.15);
  border-radius: toRem(16);
  margin-bottom: toRem(30);
  font-family: $defaultFont;

  &__inner {
    width: toRem(440);
    margin: 0 auto;
    padding: toRem(24) 0;

    &--full-width {
      width: 100%;
    }
    .ant-steps {
      font-size: toRem(14);
    }
  }
  &__header {
    font-size: toRem(16);
    font-weight: 800;
    text-align: center;
  }
  &__subHeader {
    font-size: toRem(14);
    text-align: center;
    color: rgba($primaryBlack, 0.6);
    margin-bottom: toRem(15);
  }
}
