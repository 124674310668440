@import '~variables';
@import '~mixins';

.drawer-role {
  &__grants {
    .ant-form-item-label {
      width: 100%;
      height: toRem(40);
      background-color: $primaryGrayLightest;
      border-bottom: $grayBorder;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: toRem(12);
    }

    .ant-form-item-control {
      padding: toRem(6);
    }

    &__settings {
      width: 100%;

      &--action {
        float: left;
        margin-left: toRem(12);
      }

      &--delete {
        float: right;
        margin-right: toRem(12);
      }
    }

    &__remove-btn {
      .anticon.anticon-delete {
        margin-top: toRem(12);
        font-size: toRem(16);
      }

      .anticon.anticon-delete:hover {
        color: $colorFontDefault;
      }
    }
  }
}
