@import '~variables';
@import '~mixins';

.importance-indicator {
  &__badge {
    height: toRem(31);
    width: toRem(30);
    font-size: toRem(12);
    border-radius: toRem(4);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: toRem(8);
    color: $primaryWhite;
    font-weight: 500;
  }

  &--critical {
    .importance-indicator__badge {
      background-color: $priorityRed;
    }
  }

  &--high {
    .importance-indicator__badge {
      background-color: $priorityOrange;
    }
  }

  &--moderate {
    .importance-indicator__badge {
      background-color: $priorityYellow;
    }
  }

  &--low {
    .importance-indicator__badge {
      background-color: $priorityGreen;
    }
  }

  &--planning {
    .importance-indicator__badge {
      background-color: $priorityCyan;
    }
  }

  &--large{
    .importance-indicator__badge {
      height: toRem(40);
      width: toRem(40);
      font-size: toRem(18);
      margin-left: toRem(5);
      border-radius: toRem(4);
    }
  }
}
