@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.language-switcher__flag {
  height: toRem(18);
  width: toRem(18);
  border-radius: 50%;
  @include respond-above(xl) {
    height: toRem(24);
    width: toRem(24);
  }
}
.language-switcher__text {
  font-size: toRem(12);

  @include respond-above(xl) {
    font-size: toRem(14);
  }
}
.top-header__lang-switcher .ant-space {
  gap: toRem(4) !important;
  @include respond-above(xl) {
    gap: toRem(8) !important;
  }
}

.language-switcher__tooltip {
  font-size: toRem(12);
}
