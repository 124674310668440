@import '~mixins';
@import '~variables';

.billing-report-detail {

 &__check-icon {

   &.anticon {
     font-size: toRem(16);
     color: $primaryGreen;
     margin-right: toRem(8);
   }
 }

  &__warning-icon {

    &.anticon {
      font-size: toRem(16);
      color: $primaryRed;
      margin-right: toRem(8);
    }
  }
}
