@import '~mixins';
@import '~variables';

.itsm-expense-detail {
  &__price-container {
    display: flex;
    align-items: center;
    margin-bottom: toRem(15);
    .ant-form-item {
      margin-bottom: 0;
    }
    .form-item-wrapper {
      margin-right: toRem(10);
    }
  }
  &__currency-container {
    font-size: toRem(18);
  }

  &__table {
    margin-bottom: toRem(30);
  }
}

.itsm-expense-detail__form {
  .form-item-wrapper .ant-radio-group {
    padding: 0;
    background: none;
  }
  .ant-radio-button-wrapper {
    height: toRem(39);
  }

  .ant-radio-button-wrapper {
    line-height: toRem(26);
    color: $primaryBlueNew;
    font-size: toRem(14);
    font-weight: 400;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: $primaryOrange;
    border: none;
    span {
      color: $primaryWhite;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: $primaryWhite;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: $primaryWhite;
  }

  .ant-radio-button-wrapper {
    border-radius: $primaryRadius;
    border: toRem(1) solid $primaryBlueLightest;
  }

  .form-item-wrapper .ant-radio-button-wrapper {
    margin: toRem(6) toRem(10) toRem(6) 0;
  }
}
