@import '~variables';
@import '~mixins';

.time-log-details {
  &__divider {
    margin: 0;
  }

  &__link {
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    display: inline-block;
    text-align: center;
  }
  &__cancel {
    color: $priorityRed !important;
    background-color: $primaryWhite !important;
    border-color: $priorityRed !important;
    font-weight: 500;
    &:hover,
    &:focus,
    &:active {
      background-color: $primaryWhite !important;
      border-color: $priorityRed !important;
      color: $priorityRed !important;
    }
    margin-bottom: toRem(-32);
  }
}

.time-log-details__error-hidden {
  display: none;
}

.time-log-details__error {
  position: absolute;
  bottom: toRem(6);
  padding-left: toRem(30);
  color: $priorityRed;
}
