@import '~variables';
@import '~mixins';

.incident-task-tabs {
  padding-top: toRem(30) !important;
  margin-top: toRem(15) !important;
  border-top: $grayBorder;
  padding-bottom: toRem(30) !important;

  &__state-tab {
    margin-left: toRem(-14);
  }

  h3 {
    font-weight: 500;
  }
}
