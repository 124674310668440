@import '~mixins';

.billing-items {
  .ant-table-expanded-row {
    .ant-table-wrapper {
      max-width: toRem(1000);
      width: auto;
    }

    .ant-table .ant-table-container::after {
      box-shadow: none;
    }
  }

  &__export {
    display: flex;
    justify-content: flex-end;
    .ant-form-item {
      margin-bottom: 0;
      padding-bottom: 0;
      &:first-child {
        margin-right: toRem(10);
      }
    }
  }
}

.table-advanced-filter--billing_items {
  width: toRem(460) !important;
  .ant-picker-range {
    width: toRem(400);
  }
}
