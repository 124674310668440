@import '~variables';
@import '~mixins';
@import '~mediaQueries';

$yellow: #ffdf32;
$black: #121d2a;

.services-tab {
  text-align: start;
  height: 100%;
}

.services-tab__step {
  display: none;
  font-style: normal;
  font-weight: 600;
  font-size: toRem(14);
  line-height: toRem(17);
  letter-spacing: -0.01em;
  color: $black;
  opacity: 0.3;
}

.services-tab__title {
  margin-bottom: 0;
  margin-right: toRem(8);
  font-family: $primaryFont;
  font-style: normal;
  font-weight: 600;
  font-size: toRem(20);
  line-height: toRem(27);
  white-space: break-spaces;
  letter-spacing: -0.01em;
  color: $black;
}

.services-tab__icon {
  display: none;
  height: toRem(74);
  background: $yellow;
  padding: toRem(18);
  align-self: end;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba($black, 0.15);
}

.ant-tabs-tab-active .services-tab__icon {
  background: $yellow;
}

@include respond-above(md) {
  .services-tab {
    height: 100%;
  }

  .services-tab__step {
    display: block;
  }

  .services-tab__icon {
    display: flex;
  }
}