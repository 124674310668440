@import '~variables';
@import '~mixins';

.services-feature {
  display: flex;
  align-items: center;
  font-family: $primaryFont;
  font-style: normal;
  font-weight: 400;
  font-size: toRem(14);
  line-height: toRem(17);
  color: #121d2a;
}
