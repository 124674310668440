@import '~mixins';
@import '~variables';

@media (min-width: 768px) {
  .time-session {
    table {
      width: auto !important;
      .ant-divider-horizontal {
        margin: toRem(5) 0;
      }
    }
  }
}

.time-session {
  .data-table-title {
    align-items: normal;
  }
  h4 {
    margin-top: toRem(35);
    margin-bottom: 0;
    font-weight: 400;
    font-size: toRem(16);
  }
  &__flex-container {
    display: flex;
    align-items: center;
    .ant-btn {
      display: flex;
      justify-content: center;
      margin-right: toRem(10);
    }
  }
}

.time-spans {
  margin-top: toRem(20);

  &__divider {
    margin: 0;
  }

  .ant-table-cell span {
    display: flex;
  }
  .ts_link {
    font-weight: 800;
    cursor: pointer;
    text-transform: uppercase;
    font-size: toRem(11);
    span {
      text-align: left !important;
    }
  }

  .ant-picker {
    min-width: toRem(200);
  }

  &--new {
    margin-top: toRem(15);
    background-color: $primaryWhite;
    padding: toRem(16);
    .ant-picker {
      width: 100%;
    }

    table {
      width: 100% !important;
    }

    .ant-table-tbody > tr {
      & > td {
        padding-top: toRem(5);
        border: 0;
        padding-left: 0;
        &:last-child {
          padding-right: 0;
        }
      }
      &:hover {
        td {
          background: 0 !important;
        }
      }

      .ant-select {
        width: 100%;
      }
    }
  }
}

.ts-modal {
  .ant-modal-body {
    .time-spans--new {
      padding: 0;
    }
    padding: toRem(30);
    .ant-table table {
      width: 100% !important;

      .ant-table-cell {
        padding-left: 0;
      }
    }
  }
}

.modal-wrapper {
  &.ant-modal-wrap {
    z-index: 1000;
  }
}
