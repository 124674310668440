@import '~mixins';

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;

  &__icon {
    font-size: toRem(100);
  }

  button {
    margin-top: toRem(15);
  }
}
