@import '~variables';
@import '~mixins';

.wysiwyg-editor {
  margin-bottom: toRem(15);
  background: $primaryWhite;
  border-radius: $primaryRadius;
  overflow: hidden;

  &--mb-0 {
    margin-bottom: 0 !important;
  }

  .rdw-editor-main {
    border: $grayBorder;
    padding: toRem(6) toRem(5) 0;
    line-height: normal;
    min-height: toRem(150);

    .rdw-link-decorator-wrapper {
      img {
        display: none;
      }
      &:focus {
        outline: 0;
      }
    }
  }

  .rdw-dropdown-selectedtext {
    color: $colorFontDefault;
  }

  .rdw-editor-toolbar {
    margin-bottom: 0;
    border: $grayBorder;
    border-bottom: 0;
  }
}
