@import '~mixins';
@import '~mediaQueries';

.setup-call-step .calendly-inline-widget {
  height: 950px!important;
}

.setup-call-step .setup-call-step__button {
  width: 100%;
}

@include respond-above(md) {
  .setup-call-step .calendly-inline-widget {
    height: 750px!important;
  }

  .setup-call-step .setup-call-step__button {
    width: unset;
    margin-left: toRem(120);
  }
}