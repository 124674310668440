@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.list-table-header__skeleton {
  display: flex;
  gap: toRem(10);
  margin-bottom: toRem(24);
}

.list-table-header__nav {
  display: block;

  &--left {
    display: block;
  }
}

.list-table-header__nav button.ant-btn {
  background: $primaryGray;

  &:hover,
  &:focus {
    background: 0;
    color: $primaryBlueNew;
    border-color: $primaryBlueLightest;
  }
}

.list-table-header__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  gap: toRem(10);
}

.list-table-header__filter-bar {
  display: none;

  &--hidden {
    visibility: hidden;
  }
}

.list-table-header__table-customizer {
  display: none;
}

@include respond-above(lg) {
  .list-table-header__filter-bar {
    display: flex;
  }

  .list-table-header__nav {
    display: flex;
  }

  .list-table-header__quick-search {
    margin-bottom: 0;
  }

  .list-table-header__table-customizer {
    display: block;
  }
}
