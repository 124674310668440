@import '~variables';
@import '~mixins';

$tileMargin: 6;

.tile {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: toRem(280);
  width: calc(50% - #{$tileMargin * 2}px);
  padding: toRem(18) toRem(12);
  border: $grayBorder;
  border-radius: toRem(4);
  box-shadow: 0 toRem(2) toRem(4) - toRem(2) $colorShadowDefault;
  cursor: pointer;
  transition: all 0.3s;
  background-color: $primaryWhite;
  &:nth-child(even) {
    margin: toRem(6) 0 toRem(6) toRem(12);
  }
  &:nth-child(odd) {
    margin: toRem(6) toRem(12) toRem(6) 0;
  }
  &:hover {
    box-shadow: 0 toRem(2) toRem(8) $colorShadowDefault;
  }

  &__header {
    height: toRem(64);
    flex-shrink: 0;
    padding-bottom: toRem(12);
    border-bottom: $grayBorder;
    margin-bottom: toRem(12);
  }

  &__name {
    font-weight: bold;
    font-size: toRem(20);
    margin-bottom: toRem(3);
    line-height: toRem(28);
  }

  &__description {
    line-height: toRem(20);
    margin-left: toRem(8);
    width: 50%;
  }

  &__details {
    display: flex;
    justify-content: space-between;
  }

  &__detail-item {
    display: flex;
    width: toRem(164);
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: toRem(12);

    label {
      font-size: toRem(12);
      color: $primaryGrayDark;
      line-height: toRem(16);
    }
  }

  &__progress-container {
    display: flex;
    width: 100%;

    .ant-progress {
      width: toRem(60);
      margin-right: toRem(10);
    }
  }

  &__status {
    font-size: toRem(12);
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    color: $primaryGrayDark;
    font-size: toRem(14);
    width: 100%;
    height: 100%;
  }

  &__footer-item {
    width: calc(100% / 2 - calc(16 / 16 * 1rem));
  }

  &__skeleton {
    position: relative;
    padding: 0;

    &-item {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: #{$tabletScreen}px) {
    width: calc(100% - #{$tileMargin * 2}px);
  }
}
