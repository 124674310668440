@import '~variables';
@import '~mixins';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

.create-user__form {
  .ant-input-number {
    width: 100%;
  }
  .ant-form-item {
    width: 100%;
    margin-bottom: toRem(15);
  }

  .ant-form-item-label {
    label:not(.ant-form-item-required) {
      &:after {
        content: '(optional)';
        margin-left: toRem(4);
      }
    }
  }
}

.create-user__btns {
  display: flex;
  justify-content: center;
  margin-top: toRem(15);
}

.create-user__terms {
  height: toRem(60);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: $primaryOrange;
  }
  .ant-checkbox-wrapper {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: $primaryOrange;
      }
      &:after {
        border-color: $primaryOrange;
      }
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: $primaryOrange;
      }
    }
  }

  .ant-form-item-has-error {
    .ant-form-item-explain-error {
      display: none;
    }
    .ant-checkbox-inner {
      border-color: $primaryRed;
    }
  }
  .form-item-wrapper {
    height: toRem(32);
  }

  .ant-checkbox-wrapper {
    margin: 0 toRem(6);
  }

  a {
    padding-left: toRem(4);
  }
}

.create-user__terms-error {
  flex: 0 100%;
  color: $primaryRed;
  text-align: center;
  font-size: toRem(12);
  position: relative;
  top: toRem(-8);
}


@media screen and (min-width: 992px) {
  .create-user {
    h4 {
      margin-top: toRem(30);
    }
    .ant-form {
      max-width: toRem(1000);
      margin: 0 auto;
    }
  }
}
