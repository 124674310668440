@import '~mixins';
@import '~variables';
@import '~mediaQueries';


.account-default-steps__wrapper {
  margin-right: toRem(10);
  margin-left: toRem(10);
  max-width: toRem(1080);
  width: 100%;
}

.account-default-steps__wrapper .overlay-spinner {
  height: calc(50vh - #{toRem(71)});
}

@include respond-above(xs) {
  .account-default-steps__wrapper {
    margin-right: toRem(20);
    margin-left: toRem(20);
  }
}

@include respond-above(md) {
  .account-default-steps__wrapper {
    margin-right: toRem(40);
    margin-left: toRem(40);
  }
}
