@import '~variables';
@import '~mixins';

.export-btn.ant-select.ant-select-single {
  height: toRem(39);
  border: none;

  &:hover,
  &.ant-select-focused.ant-select-single {
    .ant-select-selector {
      background: 0;
      color: $primaryBlueNew;
      border-color: $primaryBlueLightest;
      box-shadow: none;
      .ant-select-selection-item {
        color: $primaryBlueNew;
      }
    }
  }

  .ant-select-arrow {
    left: toRem(12);
    svg {
      color: $primaryBlueNew;
      font-size: toRem(14);
    }
  }
  .ant-select-selector {
    background: $primaryGray;
    min-width: toRem(98);
    border-color: $primaryBlueLightest;
    color: $primaryBlueNew;
    border-radius: toRem(4);
    padding: toRem(10) toRem(16);
    display: flex;
    align-items: center;
    font-size: toRem(14);
    height: 100%;

    .ant-select-selection-item {
      padding-right: 0;
      padding-left: toRem(15);
    }
  }
}
