@import '~variables';
@import '~mixins';

.incident-k-request-tile-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: toRem(10);
    border-top: $grayBorder;

    &__no-data {
        font-size: toRem(18);
        color: $colorFontDefaultDisabled;
        display: flex;
        width: 100%;
        height: toRem(50);
        justify-content: center;
        align-items: center;
    }
}
