@import '~variables';
@import '~mixins';

.incident-k-request-list {
  &__export {
    width: toRem(80);
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
      color: $primaryGrayDark;
      display: flex;
      align-items: center;

      &:hover {
        background: $colorBackgroundGrayDark;
      }
    }
  }
}

.table-footer-wrapper {
  display: flex;
  position: relative;
  top: toRem(-10);
  & .pagination {
    margin: 0;
  }
}
.tile-table-footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.alert {
  text-align: center;
  margin-top: toRem(20);
}

.list-table-header__top-table-right {
  display: flex;
  gap: 8px;
  margin-left: auto;
}