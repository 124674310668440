@import '~mixins';
@import '~variables';

.statistics-card-skeleton {
  height: 100%;
  width: 100%;
}

.statistics-card-skeleton__title {
  margin-bottom: toRem(8);
}

.statistics-card-skeleton__growth {
  margin-bottom: toRem(35);
}

.statistics-card-skeleton__tile-list {
  display: flex;
  justify-content: flex-start;
  .skeleton-item {
    margin-right: toRem(20);
  }
}
