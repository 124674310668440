@import '~variables';
@import '~mixins';

.transfer-for-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-transfer-list {
    width: 50%;
    .ant-input {
      background: 0;
    }
  }
  .ant-transfer-list-content {
    height: 100%;
  }
  .ant-transfer-operation {
    margin: 0 toRem(20);
  }
  .ant-transfer-operation .ant-btn {
    min-width: auto;
  }
  .ant-transfer-list-content-item-text {
    text-align: left;
  }
}
