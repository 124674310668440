@import '~variables';
@import '~mixins';

.success-login {
  font-size: toRem(16);
  height: 100vh;
  .ant-layout-content {
    min-width: 40vw;
    max-width: 90vw;
    flex: none;
    margin: auto auto;
    padding: toRem(20) toRem(50);
    border: $grayBorder;
    box-shadow: 0 toRem(2) toRem(4) 0 rgba(0, 0, 0, 0.03);
    border-radius: toRem(2);
  }
  &__footer {
    background: transparent;
    font-size: toRem(10);
    font-weight: bold;
    line-height: toRem(16);
    color: $primaryGrayDark;
    font-family: $defaultFont;
    text-align: center;
    width: 100%;
    clear: both;
  }
}
