@import '~variables';
@import '~mixins';

.incident-task-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: toRem(-6);

  &__left-side,
  &__right-side {
    display: flex;
    align-items: center;
  }

  &__number-id {
    color: $colorFontDefault;
    font-size: toRem(20);
  }

  &__indicator {
    margin-left: toRem(18);

    .importance-indicator__label {
      font-size: toRem(12);
    }
  }
}
