@import '~variables';
@import '~mixins';
@import '~mediaQueries';

$white: #ffffff;
$black: #121d2a;
$yellow: #ffdf32;
$border-color: #8f8b8b;

.services-tab-pane__prices {
  padding: toRem(30) toRem(27) toRem(34) toRem(32);
  border: none;
  background: #f6f8fa;
  border-radius: toRem(20) toRem(20) toRem(20) 0;
}

.ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane,
.ant-tabs-left
  > div
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding: 0;
}

.services-tab-pane__title {
  font-family: $primaryFont;
  font-style: normal;
  font-weight: 500;
  font-size: toRem(16);
  line-height: toRem(19);
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.01em;
  color: $black;
}

.services-tab-pane__tab-content {
  padding: toRem(6) toRem(8);
  background: $white;
  border-radius: toRem(8);
  transition: 500ms;
}

.services-tab-pane__tab-content .ant-form-item {
  margin-bottom: 0;
}

.services-tab-pane__tab-content .ant-radio-group,
.ant-checkbox-group {
  flex-direction: column;
}


@include respond-above(sm) {
  .services-tab-pane__tab-content .ant-radio-group,
  .ant-checkbox-group {
    flex-direction: unset;
  }
}
