@import '~variables';
@import '~mixins';

h1 {
  color: $colorFontDefault;
  font-size: toRem(46);
  font-family: $defaultFont;
  letter-spacing: toRem(-0.48);
  line-height: toRem(63);
}
h2 {
  color: $colorFontDefault;
  font-size: toRem(27);
  font-family: $defaultFont;
  letter-spacing: toRem(-0.48);
}
h3 {
  color: $colorFontDefault;
  font-size: toRem(28);
  font-family: $defaultFont;
  line-height: toRem(32);
}
h4 {
  color: $colorFontDefault;
  font-size: toRem(22);
  font-family: $defaultFont;
  line-height: toRem(20);
}
h5 {
  color: $colorFontDefault;
  font-size: toRem(18);
  font-family: $defaultFont;
  line-height: toRem(20);
}
h6 {
  color: $colorFontDefault;
  font-size: toRem(16);
  font-family: $defaultFont;
  line-height: toRem(20);
}
