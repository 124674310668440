@import '~variables';
@import '~mixins';

.ant-dropdown {
  .ant-menu,
  .ant-dropdown-menu {
    font-size: toRem(16);
    background: $primaryWhite;
    color: $primaryGrayDark;

    .ant-menu-item,
    .ant-dropdown-menu-item {
      color: $primaryGrayDark;
      background: transparent;
      font-size: toRem(16);
      padding: toRem(6) toRem(14);
      margin: 0 0 toRem(1) 0;

      &:hover,
      &:focus {
        background: transparent;
        color: $primaryBlack;
      }
    }
  }
}
