@import '~mixins';
@import '~mediaQueries';
@import '~variables';

.billing-reports {
  .checkbox-hidden {
    .ant-checkbox-inner {
      display: none;
    }
  }
  &__export {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: toRem(244);
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  .ant-select {
    margin: 0 toRem(8);
  }
  .ant-select {
    &.export-btn {
      &.ant-select-single {
        &.ant-select-show-arrow {
          &.ant-select-disabled {
            .ant-select-selector {
              background: $inputDisabledGrey;
              color: rgba($color: $primaryBlueNew, $alpha: 0.6);
            }
          }
        }
      }
    }
  }
}
@include respond-above(sm) {
  .billing-reports__header {
    display: flex;
    margin-bottom: toRem(24);
    justify-content: space-between;
    flex-direction: row;
    height: unset;
  }
  .billing-reports__header-exports {
    display: flex;
  }
}
.billing-reports__header-button {
  &.button.ant-btn {
    height: toRem(39);
  }
}
