@import '~mixins';
@import '~variables';

.new-location-button {
  &__label {
    font-size: toRem(13);
    cursor: pointer;

    .anticon {
      display: inline-block;
      margin-left: toRem(5);
    }
  }
}
.new-location-button__label-disabled {
  font-size: toRem(13);
  color: $primaryGreyLighter;
  .anticon {
    display: inline-block;
    margin-left: toRem(5);
  }
}
