@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.user-menu {
  &__avatar {
    color: $colorFontDefault;
    background-color: $primaryWhite;
    cursor: pointer;
  }
  &__link {
    display: flex;
    align-items: center;
    color: $colorFontDefault;

    &:hover {
      color: $colorFontDefault;
    }
  }

  &__avatar-icon {
    &.anticon {
      color: $primaryGrayDark;
      font-size: toRem(16);
    }
  }
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 0 toRem(14);
    margin: 0;
  }
}
.user-menu_logout-icon {
  color: $primaryOrange;
  margin-left: toRem(8);
}
.user-menu .ant-dropdown-menu-title-content {
  font-size: toRem(12);
}
.user-menu .ant-dropdown-menu-item {
  padding: 0 toRem(14);
}
.user_menu-name {
  font-weight: 600;
  font-size: toRem(12);
}

.user_menu-email {
  font-size: toRem(10);
}

@include respond-above(xl) {
  .user-menu_logout-icon {
    color: $primaryOrange;
    margin-left: toRem(8);
  }
  .user-menu .ant-dropdown-menu-title-content {
    font-size: toRem(16);
  }
  .user-menu .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: toRem(4) toRem(14);
    margin: 0;
  }
  .user_menu-name {
    font-weight: 600;
    font-size: toRem(16);
  }

  .user_menu-email {
    font-size: toRem(14);
  }
}
