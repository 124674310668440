@import '~mixins';
@import '~variables';

.payment-step {
  position: relative;
  min-height: toRem(200);
}

.payment-step__title {
  text-align: center;
}

.payment-step__icon {
  font-size: toRem(50);
  color: $primaryOrangeLight;
}

.payment-step h3.ant-typography,
div.ant-typography-h3,
div.ant-typography-h3 > textarea,
.ant-typography h3 {
  margin-bottom: toRem(24);
}

.payment-step__pay-now.button.ant-btn.ant-btn-primary {
  background: $primaryBlueNew;
  border-color: $primaryBlueNew;

  &:hover {
    background: $primaryWhite;

    .button__title {
      color: $primaryBlueNew;
    }
  }
}

.payment-step__form {
  padding-left: toRem(32);
  padding-right: toRem(32);
}

.payment-step .calendly-inline-widget {
  height: 750px !important;
  margin-bottom: -100px;
  transform: translateY(-50px);
}