@import '~variables';
@import '~mixins';

.action-buttons-container {
  box-sizing: border-box;
  color: $primaryGrayLightest;
  text-align: center;
  padding: toRem(3) toRem(5);
  display: flex;
  position: fixed;
  background-color: $primaryBlack;
  border-radius: toRem(4);
  -webkit-box-shadow: 0 toRem(2) toRem(8) $colorShadowDefault;
  box-shadow: 0 toRem(2) toRem(8) $colorShadowDefault;
  left: toRem(150);
  transform: translateX(-50%);
  z-index: 10000;
  white-space: nowrap;

  &__button {
    border: 0;
    background: 0;
    cursor: pointer;
    width: toRem(62);
    font-size: toRem(11);
    padding: toRem(3);
  }

  &:focus {
    outline: 0;
  }
}
