.location-select {
  label,
  .ant-form-item-label {
    width: 100%;
  }

  &__label {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
