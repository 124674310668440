@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.incident-header {
  @include respond-above(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__left-side {
    margin-bottom: toRem(20);
    @include respond-above(md) {
      margin-bottom: 0;
    }
  }

  &__left-side,
  &__right-side {
    display: flex;
    align-items: center;
  }

  &__number-id {
    color: $colorFontDefault;
    font-size: toRem(14);
    @include respond-above(md) {
      font-size: toRem(20);
    }
  }

  &__indicator {
    margin-left: toRem(18);

    .importance-indicator__label {
      font-size: toRem(12);
    }
  }
}
