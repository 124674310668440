@import '~variables';
@import '~mixins';

@mixin hover-styles {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin input-styles {
  height: toRem(39);
  border: toRem(1) solid $primaryBlueLightest;
  border-radius: $primaryRadius;
  box-shadow: none;
  background-color: $primaryWhite;

  @include hover-styles {
    border-color: $primaryBlue;
    box-shadow: none;
  }
}

@mixin error-styles {
  height: toRem(39);
  box-shadow: none;
  opacity: 1;
  border-color: $primaryRed;

  @include hover-styles {
    border-color: $primaryRed;
    box-shadow: none;
    background-color: $primaryWhite;
    opacity: 1;
  }
}

@mixin disabled-styles {
  background-color: $inputDisabledGrey;
  color: $primaryBlack;
  opacity: 0.5 !important;
  border-color: white;
  cursor: not-allowed;
}

.form-item-wrapper {
  font-family: $defaultFont;
  font-weight: 500;

  .ant-form-item-control-input-content div.ant-picker,
  .ant-form-item-control-input-content .ant-input-group,
  .ant-input-number,
  .ant-input,
  .wysiwyg-editor,
  .ant-input-search .ant-input,
  .ant-input-search-button,
  .ant-picker-range,
  .select.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  textarea.ant-input {
    @include input-styles;
  }

  .wysiwyg-editor {
    height: auto;
  }

  .ant-input-number-handler-wrap {
    border-bottom-right-radius: $primaryRadius;
    border-top-right-radius: $primaryRadius;
  }

  .ant-input-search-button {
    border: 0;
  }
  .ant-input-group-addon {
    border: 0;
  }

  .ant-select {
    &.ant-select-single .ant-select-selector,
    &.ant-select-multiple .ant-select-selector,
    &.ant-select-multiple.ant-select-focused div.ant-select-selector,
    &.ant-select-single.ant-select-focused div.ant-select-selector {
      @include input-styles;
    }

    &.ant-select-multiple.ant-select-disabled,
    &.ant-select-single {
      &.ant-select-disabled .ant-select-selector {
        @include disabled-styles;
      }
    }
    height: 100%;
    .ant-select-selection-item,
    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }

    &.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
      background-color: $inputDisabledGrey;
      color: $primaryBlack;
    }
    &.ant-select-multiple .ant-select-selector {
      min-height: toRem(39);
      height: auto;
    }
  }

  .ant-form-item-has-error {
    textarea.ant-input,
    div.ant-picker,
    .ant-picker-range,
    .ant-form-item-control-input-content .ant-input,
    .ant-input {
      @include error-styles;
    }

    &.ant-row .ant-select {
      &.ant-select-single .ant-select-selector,
      &.ant-select-multiple .ant-select-selector,
      &.ant-select.ant-select-multiple.ant-select-focused
        div.ant-select-selector,
      &.ant-select.ant-select-single.ant-select-focused
        div.ant-select-selector {
        @include error-styles;
      }
    }
  }

  .ant-checkbox-wrapper {
    .ant-checkbox-inner {
      height: toRem(20);
      width: toRem(20);
      border-radius: toRem(8);
    }

    .ant-checkbox-inner:after {
      height: toRem(10);
      width: toRem(7);
      border-color: $primaryWhite;
    }

    .ant-checkbox.ant-checkbox-disabled .ant-checkbox-inner {
      background-color: $inputDisabledGrey;
    }
  }

  .ant-checkbox-wrapper-checked {
    .ant-checkbox-checked {
      &:after {
        border: none;
      }
      .ant-checkbox-inner {
        background-color: $primaryButtonOrange;
        border: none;

        &:after {
          border-color: $primaryWhite;
        }
      }
    }

    .ant-checkbox.ant-checkbox-disabled .ant-checkbox-inner {
      background-color: $inputDisabledGrey;

      &:after {
        border-color: $primaryWhite;
      }
    }
  }

  .ant-radio-group {
    padding: toRem(11) toRem(13) toRem(10);
    background: $primaryButtonOrange;
    border-radius: toRem(8);
  }

  .ant-radio-button-wrapper {
    padding: toRem(6) toRem(10);
    background: none;
    border: none;
  }

  .ant-radio-button-wrapper::before {
    background: none;
  }

  .ant-input[readonly],
  .ant-picker.ant-picker-disabled,
  .ant-input[disabled] {
    @include disabled-styles;
  }

  .ant-form-item-label {
    label {
      font-size: toRem(12);
      color: $primaryBlack;
    }
  }

  .ant-form-item-explain-error,
  .ant-form-item-explain-success {
    font-size: toRem(12);
    padding-top: toRem(4);
  }

  .ant-form-item-control-input-content textarea.ant-input {
    height: 100%;
  }

  .ant-picker-range {
    width: 100%;
  }

  &__grey {
    input,
    .ant-form-item-control-input-content div.ant-picker,
    .ant-form-item-control-input-content .ant-input-group,
    .ant-input-number,
    .ant-input,
    .ant-input-search .ant-input,
    .ant-input-search-button,
    .ant-picker-range,
    input.ant-input:-internal-autofill-selected,
    textarea.ant-input {
      background-color: $primaryGray !important;
    }

    .ant-select {
      &.ant-select-single .ant-select-selector,
      &.ant-select-multiple .ant-select-selector,
      &.ant-select-multiple.ant-select-focused div.ant-select-selector,
      &.ant-select-single.ant-select-focused div.ant-select-selector {
        background-color: $primaryGray !important;
      }
    }
  }
}
