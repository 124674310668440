@import '~variables';
@import '~mixins';

.list-table-drawer {
  z-index: 999;

  .ant-drawer-content-wrapper {
    width: toRem(500) !important;
  }
}
