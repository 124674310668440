@import '~variables';
@import '~mixins';

.table-advanced-filter {
  &.ant-drawer .ant-drawer-header .ant-drawer-title {
    font-size: toRem(16) !important;
    color: $colorFontDefault;
    justify-items: flex-start;
    padding-left: 0;
    font-weight: 600;
  }

  &__reset-button.button.ant-btn {
    width: toRem(80);
    font-size: toRem(14);
    color: $priorityRed;
    border: toRem(1) solid $priorityRed;
    padding: toRem(5);
    margin-left: toRem(16);
    margin-top: toRem(15);
    background: none;
    transition: 0.2s;

    &:hover {
      background: none;
      color: $priorityRed;
    }
  }

  &__reset-button--sm.ant-btn {
    width: toRem(60);
    font-size: toRem(12);
    color: $priorityRed !important;
    border: toRem(1) solid $priorityRed;
    padding: toRem(5);
  }

  .ant-drawer-header {
    border: none;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-bottom: toRem(6);
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: toRem(6) toRem(16);
  }

  .ant-drawer-body {
    padding-top: toRem(65);
    padding-left: toRem(15);
    display: flex;
    flex-direction: column;
    background: $primaryWhite;

    .ant-select {
      width: 100%;
    }
  }

  .ant-collapse {
    background: none;
    border: none;
    overflow: hidden;
    overflow-y: auto;
    margin-top: toRem(10);

    .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      color: $colorFontDefault;
    }

    .ant-collapse-content {
      border: none;
      background: 0;
      .ant-row.ant-form-item {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .ant-collapse-item {
      border: none;
    }
  }

  &__panel {
    &--active {
      .ant-collapse-header {
        color: $primaryBlue !important;
      }
    }
  }

  &__container {
    width: toRem(300);
    background: $primaryWhite;
    border: $grayBorder;
    border-radius: toRem(4);
    & .table-advanced-filter__item {
      &:hover {
        background-color: $primaryGray;
      }
    }
  }

  &__menu-header {
    height: toRem(40);
    color: $primaryGrayDark;
    padding-top: toRem(8);
    padding-left: toRem(12);
  }

  &__item {
    height: toRem(36);
    font-size: toRem(14);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: toRem(8) toRem(12);

    &--active {
      font-weight: 600;
      background: $colorBackgroundGrayDark;
    }
  }

  &__footer-item {
    height: toRem(50);
    font-size: toRem(14);
    cursor: pointer;
    padding: toRem(12);
    font-weight: 600;
    border-top: $grayBorder;
  }

  &__name {
    margin-left: toRem(13);
  }

  &__icon {
    width: toRem(20);
  }

  &__badge {
    .ant-badge-count-sm {
      right: toRem(-2) !important;
    }
    p.ant-scroll-number-only-unit {
      font-size: toRem(10);
      color: $primaryWhite;
    }
  }
}

.table-quick-filter {
  position: relative;
}

.table-quick-filter__applied {
  font-weight: 700;
  text-decoration: underline;
  margin-left: toRem(4);
}

.table-quick-filter__cross {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  background: none;
  border: none;
  line-height: normal;
  color: $primaryGrayDark;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    transform: scale(1.2);
    color: $primaryBlackLight;
  }
}

@media screen and (max-width: $modernMobileScreen + 'px') {
  .table-advanced-filter {
    &__button {
      &.ant-btn {
        margin-left: toRem(4);
      }
    }
  }
}

.quickf- {
  font-weight: 700;
  text-decoration: underline;
  margin-left: toRem(4);
}
