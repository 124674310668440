@import '~mixins';

.logo-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  &__logo {
    width: toRem(126);
  }
}
