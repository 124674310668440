@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.toast {
  bottom: toRem(15);
  right: toRem(16);
  left: toRem(16);
  position: fixed;
  border-radius: $primaryRadius;
  z-index: 2000;
  background: $priorityCyan;
  color: $primaryWhite;
  display: none;
  border: none;
  box-shadow: $primaryBoxShadow;

  &--info-type {
    background: $priorityCyan;
  }

  &--error-type {
    background: $priorityRed;
  }

  &--warning-type {
    background: $priorityOrange;

    .toast__panel__message {
      font-size: toRem(14);
    }
  }

  &--is-visible {
    display: block;
  }
}

.toast__close-icon {
  position: absolute;
  top: toRem(10);
  right: toRem(7);
  cursor: pointer;
  z-index: 9;
}

.toast__panel {
  color: $primaryWhite !important;
  .ant-collapse-header {
    background-color: transparent;
  }
}

.toast__message {
  width: 100%;
  margin-right: toRem(10);
  display: flex;
  align-items: center;
  font-size: toRem(12);
  color: $primaryWhite;
}

.toast .anticon {
  color: $primaryWhite;
}

.toast .ant-collapse-content {
  border-radius: 0 0 $primaryRadius $primaryRadius !important;
  border: none;
}

.toast .ant-collapse-content-box {
  min-height: toRem(180);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: $primaryWhite;
  border-radius: 0 0 $primaryRadius $primaryRadius;
  height: toRem(50);
}

.toast.ant-collapse > .ant-collapse-item:last-child,
.toast.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border: none;
}

@include respond-above(xs) {
  .toast {
    left: 50%;
    right: unset;
    transform: translateX(-50%);
    width: toRem(340);
  }
}
