@import '~mixins';
@import '~variables';
@import '~mediaQueries';

.top-header-bell-notifications__icon {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.top-header-bell-notifications__icon svg {
  height: toRem(18);
  width: toRem(18);
  color: $primaryRed;
  @include respond-above(xl) {
    height: toRem(24);
    width: toRem(24);
  }
}

.top-header-bell-notifications__counter {
  position: absolute;
  top: 0;
  left: toRem(15);
  font-size: toRem(9);
  color: $primaryRed;
  font-weight: 600;
  line-height: 0;
  @include respond-above(xl) {
    left: toRem(22);
    font-size: toRem(12);
  }
}

.top-header-bell-notifications__icon--disabled {
  svg {
    color: $primaryGreyLighter;
  }

  .top-header-bell-notifications__counter {
    color: $primaryGreyLighter;
  }
}

.top-header-bell-notifications__list {
  padding-top: toRem(8);
  padding-bottom: toRem(8);
  padding-left: toRem(16);
  padding-right: 0;
  background: $primaryWhite;
  box-shadow: $primaryBoxShadow;
  border-radius: $primaryRadius;
}

.top-header-bell-notifications__list ul {
  padding: 0;
  padding-right: toRem(12);
  width: toRem(400);
  max-height: toRem(300);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.top-header-bell-notifications__list li {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  line-height: toRem(30);
}

.top-header-bell-notifications__btns {
  gap: toRem(4);
  display: flex;
  align-items: center;
}

.top-header-bell-notifications__footer {
  display: flex;
  justify-content: flex-end;
  margin-right: toRem(16);
}
