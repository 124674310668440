@import '~variables';
@import '~mixins';

.overlay-spinner {
  width: 100%;
  height: calc(100vh - toRem(71));
  display: flex;
  align-items: center;
  justify-content: center;
}
