@import '~variables';
@import '~mixins';
@import '~mediaQueries';

$selectedColor: $colorBackgroundGreen;

.accept-invite-step {
  height: toRem(51);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  &:not(:first-child):after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: toRem(20) solid $primaryWhite;
    border-top: toRem(26) solid transparent;
    border-bottom: toRem(26) solid transparent;
  }

  &:not(:first-child) {
    margin-left: toRem(10);
  }

  &:not(:last-child):before {
    content: '';
    position: absolute;
    right: -5px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: toRem(20) solid $primaryWhite;
  }

  &:last-child .accept-invite-step__content:before {
    border-left: toRem(0);
  }

  &--selected {
    .accept-invite-step__content {
      color: $primaryGreenLight;
      background: $selectedColor;

      &:before {
        border-left: toRem(20) solid $selectedColor;
      }

      &:after {
        border-left: toRem(20) solid $colorBorderDefault;
      }
    }
  }
}

.accept-invite-step__content {
  width: 100%;
  height: 100%;
  position: relative;
  background: $primaryGrayLightest;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primaryGrayDark;
  flex-direction: row-reverse;
  text-align: left;
  transition: all 0.3s;

  &:before {
    content: '';
    position: absolute;
    right: toRem(-20);
    bottom: 0;
    width: 0;
    height: 0;
    border-left: toRem(20) solid $colorBorderDefault;
    border-top: toRem(26) solid transparent;
    border-bottom: toRem(26) solid transparent;
    z-index: 1;
    transition: all 0.3s;
  }
}

.accept-invite-step__text {
  font-size: toRem(16);
  line-height: 1.2;
  width: 160px;
  text-align: center;
}
