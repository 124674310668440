@import '~variables';
@import '~mixins';

$selectedColor: $colorBackgroundGreen;

.block {
  width: toRem(332);
  height: toRem(40);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: toRem(3);
  flex-grow: 1;

  &__content {
    width: 99%;
    height: 100%;
    position: relative;
    background: $primaryGrayLightest;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primaryGrayDark;
    flex-direction: row-reverse;
    padding: 0 toRem(20) 0 toRem(35);
    text-align: left;
    transition: all 0.3s;

    &:hover {
      background: $primaryGrayLightest;
    }
    .tooltip {
      background-color: $primaryWhite;
    }
    &:not(:first-child):after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: toRem(12) solid $colorBorderDefault;
      border-top: toRem(20) solid transparent;
      border-bottom: toRem(20) solid transparent;
      transition: all 0.3s;
    }
    &:before {
      content: '';
      position: absolute;
      right: -12px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: toRem(12) solid $colorBorderDefault;
      border-top: toRem(20) solid transparent;
      border-bottom: toRem(20) solid transparent;
      z-index: 1000;
      transition: all 0.3s;
    }
    i {
      margin-left: toRem(5);
      font-size: toRem(20);
    }
  }

  &--selected:not(.block--active) {
    .block__content {
      color: $primaryGreenLight;
      background: $selectedColor;

      .assigned-to-mark__name {
        color: $primaryGrayDark !important;
      }

      &:before {
        border-left: toRem(12) solid $selectedColor;
      }

      &:after {
        border-left: toRem(12) solid $colorBorderDefault;
      }
    }
  }

  &--cursor {
    cursor: pointer;
  }

  &--active {
    &:first-child {
      margin-left: 0;

      .block__content::after {
        display: none;
      }
    }

    .block__content {
      background: $primaryGreenLight;
      cursor: pointer;

      span:not(.assigned-to-mark__name) {
        color: $primaryWhite !important;
      }

      &:after {
        border-left: toRem(12) solid $colorBorderDefault;
      }

      &:before {
        right: -12px;
        border-left: toRem(12) solid $primaryGreenLight;
        z-index: 100;
      }
    }
  }

  &:first-child .block__content {
    border-radius: toRem(4) 0 0 toRem(4);
  }

  &:last-child .block__content {
    border-radius: 0 toRem(4) toRem(4) 0;

    &::before {
      display: none;
    }
  }

  &:not(:first-child):after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: toRem(12) solid $primaryWhite;
    border-top: toRem(20) solid transparent;
    border-bottom: toRem(20) solid transparent;
  }

  &:not(:last-child):before {
    content: '';
    position: absolute;
    right: -5px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: toRem(12) solid $primaryWhite;
  }

  &__skeleton {
    .block__content {
      &:before {
        border-left: 0.75rem solid $colorBorderDefault;
      }
    }

    &-item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
