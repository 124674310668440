@import '~variables';
@import '~mixins';

.attachments-modal {
  .ant-modal-close-x {
    width: toRem(40);
    height: toRem(40);
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-body {
    padding: toRem(35) toRem(16) 0 toRem(16);
  }
  .ant-modal-footer {
    border-top: 0;
  }
  thead.ant-table-thead > tr,
  .attachments-modal tbody.ant-table-tbody > tr {
    height: auto;
  }

  .ant-table-wrapper {
    padding: 0;
  }
  thead.ant-table-thead > tr > th {
    &:first-of-type {
      padding: 0.5rem 0;
    }
  }
}
