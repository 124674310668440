@import '~variables';
@import '~mixins';

.invitation-drawer {
  &__legal-entity-input {
      cursor: pointer;

     label,
     .ant-input[disabled] {
      cursor: pointer;
    }
  }
}