@import '~variables';
@import '~mixins';

.assigned-to-mark {

  .ant-tooltip-inner {
    background-color: $primaryWhite;
    color: $colorFontDefault;
    font-size: toRem(12);
  }
  .ant-tooltip-arrow {
    display: none;
  }

  &__name {
    width: toRem(28);
    height: toRem(28);
    max-width: toRem(28);
    min-width: toRem(28);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primaryWhite;
    border-radius: 50%;
    border: toRem(1) solid $primaryGreenLight;
    font-size: toRem(12);
    cursor: pointer;
  }

  &__tooltip-title {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: toRem(100);

     div:nth-child(2) {
       color: $primaryGrayDark;
    }
  }

}
