@import '~variables';
@import '~mixins';

.tabs {
  &.ant-tabs {
    &.ant-tabs-left {
      .ant-tabs-content-holder {
        border-left: none !important;

        .ant-tabs-content > .ant-tabs-tabpane {
          padding-left: 0;
        }
      }

      & > .ant-tabs-nav {
        .ant-tabs-tab {
          color: $primaryGrayDark;
          width: toRem(222);
          margin: 0;
          text-align: left;
          border-radius: toRem(4);

          &:hover {
            color: $colorFontDefault;
          }

          &.ant-tabs-tab-active {
            background-color: $colorBackgroundBlue;

            .ant-tabs-tab-btn {
              color: $colorFontDefault;
            }
          }

          .ant-tabs-tab-btn {
            &:active {
              color: $colorFontDefault;
            }
          }
        }
      }
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  &__skeleton {
    &.ant-tabs {
      &.ant-tabs-left {
        & > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
          background-color: transparent;
        }
      }
    }
  }
}

.tabs .ant-tabs-nav {
  margin-right: toRem(20);
}