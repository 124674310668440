@import '~variables';
@import '~mixins';

$colorBgFocus: $colorBackgroundGray;

.pagination {
  margin-top: toRem(10);
  margin-bottom: toRem(10);
  display: flex;

  [ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }

  .ant-pagination-total-text {
    color: $colorFontDefault;
    padding-top: toRem(3);
    order: 2;
    flex-grow: 1;
  }

  .ant-pagination-options {
    order: 1;
    flex-grow: 1;
    width: toRem(180);
    margin-left: 0;

    &::before {
      content: "Page size:";
      color: $primaryGrayDark;
    }

    .ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        font-weight: 600;
        border: none;
      }
    }

    .ant-select-focused:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: none;
        box-shadow: none;
      }
    }

    .ant-select-item-option-content {
      color: $primaryGrayDark;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
      background-color: $primaryGrayLightest;

      .ant-select-item-option-content {
        color: $colorFontDefault;
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-next,
  .ant-pagination-item {
    order: 3;
  }

  .ant-btn {
    height: toRem(32);
    padding: 0;
    border: 0;
    border-radius: toRem(4);
    color: $colorFontDefault;
    width: toRem(32);
    min-width: toRem(32);
    max-width: toRem(32);
    font-size: toRem(14);
    font-weight: 600;
    box-shadow: none;
    margin-right: toRem(8);

    &.arrow {
      border: none;
      font-size: toRem(11);

      &:hover,
      &:focus {
        border: none;
      }
    }

    &:not([disabled]) {
      background: $primaryWhite;
    }

    &[disabled] {
      background-color: $primaryWhite;
      color: $primaryGrayDark;
    }

    &:focus {
      border: 0;
      color: $colorFontDefault;
      background-color: $colorBgFocus;
    }

    &:hover {
      border: 0;
      color: $colorFontDefault;
      background-color: $colorBgFocus;
    }
  }

  .ant-btn.active {
    border: 0;
    background-color: $primaryGrayLightest;
  }

  .ant-btn.ant-btn-dashed {
    border: none;
    background-color: transparent;
    box-shadow: none;
    vertical-align: middle;
    padding: 0;

    .anticon-ellipsis {
      letter-spacing: normal;
      color: $primaryGrayDark;
      font-size: toRem(14);
    }
  }

  .ant-btn.ant-btn-dashed .anticon-double-left,
  .ant-btn.ant-btn-dashed .anticon-double-right {
    font-size: toRem(16);
  }

  li {
    margin-right: toRem(8);
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border: none;
    }

  }

  .ant-pagination-prev,
  .ant-pagination-item,
  .ant-pagination-next {
    border: none;
    min-width: toRem(32);
    max-width: toRem(32);
    height: toRem(32);
    font-size: toRem(11);

    &:hover {
      .ant-pagination-item-link {
        border: none;
        color: $colorFontDefault;
        background-color: $colorBgFocus;
      }
    }

    &:focus {
      .ant-pagination-item-link {
        background-color: $colorBgFocus;
      }
    }
  }

  .ant-pagination-prev:hover a,
  .ant-pagination-item:hover a,
  .ant-pagination-next:hover a {
    background-color: $colorBgFocus;
    color: $colorFontDefault;
    border: none;
  }

  .ant-pagination-prev:focus a,
  .ant-pagination-item:focus a,
  .ant-pagination-next:focus a {
    color: $colorFontDefault;
    background-color: $colorBgFocus;
    border: none;
  }
  .ant-pagination-prev a,
  .ant-pagination-item a,
  .ant-pagination-next a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: none;
    font-size: toRem(14);
    font-weight: 600;
  }

  .ant-pagination-item-active {
    background-color: $primaryGrayLightest;
    border: 0;

    a {
      color: $colorFontDefault;
    }
  }

  .ant-pagination-jump-next, .ant-pagination-jump-prev {
    .ant-pagination-item-container .ant-pagination-item-link-icon {
      color: $colorFontDefault;
      font-size: toRem(11);
    }
  }

  .ant-pagination-item-container {
    .ant-pagination-item-ellipsis {
      letter-spacing: normal;
      color: $primaryGrayDark;
      font-size: toRem(9);
    }
  }

  .ant-pagination-options-size-changer {
    margin-left: toRem(8);
  }
}
