@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.purchase-module {
  font-size: toRem(16);
  font-weight: 700;
  border: toRem(1) solid transparent;
  height: 100%;
  padding: toRem(16);
  &--selected {
    border-color: $primaryBlue;
  }
}

.purchase-module__title {
  font-size: toRem(14);
}

.purchase-module__icon {
  height: toRem(24);
  width: 100%;
}

.purchase-module__price {
  flex-wrap: nowrap;
  font-size: toRem(12);
  font-weight: 500;
  text-align: right;
  color: $primaryGrayLight;
}

@include respond-above(xl) {
  .purchase-module__title {
    font-size: toRem(18);
    margin-bottom: toRem(8);
  }
  .purchase-module {
    margin-bottom: toRem(16);
  }
}
