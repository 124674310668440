@import '~mixins';
@import '~variables';

.finance-dashboard-tile-skeleton {
  margin-top: toRem(50);
  height: toRem(150);
  width: 100%;
}

.finance-dashboard-tile-skeleton__title {
  margin-bottom: toRem(26);
}

.finance-dashboard-tile-skeleton__total {
  margin-bottom: toRem(10);
}
