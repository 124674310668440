@import '~mixins';

.drawer-form {
  height: 100%;
  & > div:first-child {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (min-width: 992px) {
  .drawer-form {
    .col-flex {
      &--two {
        .form-item-wrapper {
          width: calc(100% - 40px / 2);
        }
        .form-item-wrapper:nth-child(1) {
          margin-right: toRem(20);
        }
      }
    }
  }
}
