@import '~mixins';
@import '~variables';

.event-form {
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    h2 {
      margin: 0;
    }
  }

  &__hideMsg .ant-form-item-explain div:nth-child(2) {
    display: none;
  }

  &__ticket-details {
    margin-top: toRem(15);
    padding: toRem(15) 0;
    border-top: $grayBorder;

    h3 {
      font-weight: 500;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $colorFontDefault;
    }

    .ant-tabs.ant-tabs-left .ant-tabs-tab {
      color: $primaryGrayDark;
      margin: 0;
      text-align: left;
      padding: toRem(8) toRem(45) toRem(16) 0;
      &:hover {
        color: $primaryWhite;
      }
    }

    .ant-tabs-ink-bar {
      background-color: $primaryBlack;
    }
  }

  .location-select {
    margin-bottom: toRem(10);
    margin-right: toRem(15);
  }
  .ant-btn {
    min-width: toRem(80);
  }

  .ant-input-number {
    width: 100%;
  }
  .ant-picker {
    width: 100%;
  }
}

.itsm-add-city-btn-wrap {
  display: flex;
  flex-wrap: nowrap;
  padding: toRem(8);
}
.itsm-add-city-btn {
  flex: none;
  padding: toRem(3);
  display: block;
  cursor: pointer;
  min-width: toRem(96);
  span:nth-child(2) {
    margin-left: toRem(2);
  }
}
