@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.purchase-addon {
  position: relative;
  padding: toRem(14) toRem(10) toRem(10) toRem(10);
  border: toRem(1) solid $primaryGreyLighter;
  border-radius: $largeBorderRadius;
  transition: 0.2s;

  &--checked {
    border-color: $primaryBlue;
    background: $primaryWhite;
  }

  &--active {
    cursor: pointer;

    &:hover {
      transition: 0.2s;
      border-color: $primaryBlack;
    }
  }
}
.purchase-addon__title {
  font-size: toRem(11);
}

.purchase-addon__checkbox-wrapper {
  position: absolute;
  top: toRem(1);
  right: toRem(4);
}

.purchase-addon__price {
  margin-left: auto;
  font-size: toRem(10);
}

.purchase-addon .anticon svg {
  height: toRem(10);
  width: toRem(10);
}
.purchase-addon.ant-collapse-item.ant-collapse-item {
  padding: toRem(20);
}

.purchase-addon.ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: toRem(10);
  right: 0;
}

.purchase-addon.ant-collapse-item > .ant-collapse-header {
  padding: 0;
}

.purchase-addon__checkbox {
  &--required {
    &.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
      background: $primaryGreen;
    }

    .ant-checkbox-input {
      cursor: default;
    }
  }
}

.purchase-addon
  .form-item-wrapper
  .ant-checkbox-wrapper
  .ant-checkbox-inner:after {
  height: toRem(8);
  width: toRem(5);
  left: 25%;
}

.purchase-addon .ant-collapse-item.ant-collapse-item {
  border-bottom: none;
}

.purchase-addon.ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0;
}

.purchase-addon .ant-collapse-header-text {
  width: 100%;
}

.purchase-addon__arrow {
  display: flex;
  justify-content: center;
  font-size: toRem(12);
}
@include respond-above(xl) {
  .purchase-addon__checkbox-wrapper {
    top: toRem(4);
  }
  .purchase-addon {
    padding: toRem(24) toRem(16) toRem(16);
  }
  .purchase-addon .anticon svg {
    height: toRem(14);
    width: toRem(14);
  }
  .purchase-addon__price {
    font-size: toRem(14);
  }
  .purchase-addon__title {
    font-size: toRem(14);
  }
}
