@import '~variables';
@import '~mixins';

.drawer {
  .ant-drawer-body {
    padding-top: toRem(95);
    background: $primaryGray;
  }

  .form-item-wrapper .ant-select {
    .ant-select-selection-item,
    .ant-select-selection-search .ant-select-selection-search {
      max-width: toRem(170);
    }
  }
  .ant-drawer-header {
    align-items: center;
    display: flex;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    min-height: toRem(62);
    background-color: $primaryWhite;
    z-index: 999;
    padding: toRem(12) toRem(24);
    border-bottom: $grayBorder;

    .ant-drawer-title {
      padding-left: toRem(12);
      font-size: toRem(24);
      align-items: center !important;
      display: flex;
      height: toRem(40) !important;
      width: 90%;

      .drawer-header-title {
        width: 100%;
      }
    }

    .ant-drawer-title,
    .drawer-header-title,
    .ant-form-item-control,
    .ant-input[disabled] {
      width: 100%;
      display: flex;
      align-items: flex-start;
      color: $colorFontDefault;
      font-size: toRem(24);
      background: transparent;
      border: 1px solid transparent;
    }
    .ant-drawer-title,
    .drawer-header-title,
    .ant-form-item-control,
    .ant-input {
      align-items: flex-start;
      display: flex;
      color: $colorFontDefault;
      width: 100%;
    }
  }

  .drawer-footer.ant-layout-footer {
    border-top: $grayBorder;
    background-color: transparent;
    z-index: 999;
    padding: toRem(12) 0;

    .ant-btn {
      &:last-child {
        margin-left: toRem(10);
      }
    }
  }

  .ant-drawer-close {
    height: 100%;
  }

  .ant-drawer-title > h1 {
    margin: 0;
  }

  .ant-drawer-body .ant-form-vertical .ant-form-item .ant-form-item-label {
    padding-bottom: 0;
  }
}
