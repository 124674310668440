@import '~mixins';
@import '~variables';

.apex-chart__skeleton {
  min-height: toRem(362);
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.apex-chart__wrapper {
  height: 100%;
  width: 100%;
  .apexcharts-toolbar {
    z-index: 0;
  }
}
