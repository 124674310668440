@import '~mixins';
@import '~variables';
@import '~mediaQueries';

.top-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  gap: toRem(10);
}

.top-header__lang-switcher {
  display: none;
  margin-right: toRem(10);
}

.top-header__avatar {
  width: toRem(32);
  height: toRem(32);
  cursor: pointer;
}

.top-header__menu {
  display: flex;
  align-items: center;
}

.top-header__extra-content > div,
.top-header__extra-content > button {
  width: auto;
}

.top-header__inner-drawer {
  display: flex;
  flex-flow: column;
  gap: toRem(20);
}

.top-header__extra-content {
  display: flex;
  align-items: center;
  gap: toRem(10);
}

.top-header__mobile-drawer .ant-drawer-body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  padding-top: toRem(64);
}

.top-header__mobile-drawer .ant-drawer-close {
  height: auto;
}

.top-header__mobile-drawer .select {
  width: 100%;
  margin-bottom: toRem(8);
}

.top-header__mobile-drawer .org-select-dropdown {
  width: 100%;
  flex-direction: column;
}

.top-header__mobile-drawer .org-select-dropdown__select {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.top-header__inner-drawer > div {
  width: 100%;
}

@include respond-above(lg) {
  .top-header__lang-switcher {
    display: block;
  }
}
