@import '~variables';
@import '~mixins';
@import '~mediaQueries';

.checkbox-header {
  margin: toRem(32) toRem(8) toRem(18) toRem(8);
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  @include respond-above(md) {
    flex-direction: row;
  }
}
