@import '~variables';

.mobile-purchase {
  margin: 0 toRem(16);

  .mobile-purchase__header {
    position: fixed;
    z-index: 2;
    top: 0;
    background: $primaryGray;
    width: 100%;
    left: 0;
    padding: 0 toRem(16);
  }

  .mobile-purchase__logo {
    height: toRem(24);
    width: toRem(70);
    align-self: self-end;
  }

  .mobile-purchase__cart {
    position: relative;
    display: flex;
    height: toRem(28);
  }

  .mobile-purchase__cart-icon {
    font-size: toRem(24);
    color: $primaryBlueNew;
    width: toRem(50);
  }

  .mobile-purchase__counter-wrapper {
    position: absolute;
    top: 0;
    right: toRem(5);
    background-color: rgba($primaryOrange, 0.75);
    border-radius: toRem(25);
    width: toRem(17);
    height: toRem(17);
    display: flex;
    justify-content: center;
  }
  .mobile-purchase__counter {
    font-size: toRem(11);
    color: $primaryWhite;
    font-weight: 700;
  }
  .mobile-purchase-title {
    font-size: toRem(16);
    text-align: center;
    font-weight: 500;
  }
  .purchase-module {
    height: auto;
  }
  .mobile-purchase__buy-now {
    height: toRem(30);
    position: fixed;
    bottom: toRem(15);
    right: toRem(50);
  }
  .form-item-wrapper .ant-checkbox-wrapper .ant-checkbox-inner {
    height: toRem(12);
    width: toRem(12);
  }
}
.mobile-purchase
  .form-item-wrapper
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  height: toRem(25);
  background: $primaryWhite;
  border-radius: $primaryRadius;
  span {
    color: $primaryButtonOrange;
  }
}
.mobile-purchase .form-item-wrapper .ant-radio-button-wrapper {
  height: toRem(25);
}

.mobile-purchase
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: $primaryButtonOrange;
}
.mobile-purchase
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: $primaryButtonOrange;
}

.mobile-purchase .form-item-wrapper .ant-radio-button-wrapper span {
  font-size: toRem(12);
  line-height: toRem(12);
  display: flex;
  font-family: $defaultFont;
  font-style: normal;
  font-weight: 400;
  color: $primaryWhite;
}
.mobile-purchase .form-item-wrapper .ant-radio-group {
  padding-top: toRem(4);
  padding-bottom: toRem(4);
}
.mobile-purchase__content {
  margin-top: toRem(170);
}
