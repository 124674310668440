@import '~variables';
@import '~mixins';

.attachments {
  &__table {
    margin-bottom: toRem(15);
    table {
      width: toRem(800) !important;
    }
  }

  &__img {
    width: toRem(30);
  }

  &__actions {
    display: flex;
    align-items: center;
    .ant-btn[disabled] {
      background: 0 !important;
      border: 0;
    }
    &__button {
      background: 0;
      border: 0;
      padding: 0;
      min-width: toRem(30);
      box-shadow: none;
      font-weight: 500;

      &:hover,
      &:active,
      &:focus {
        background: 0 !important;
        border: 0;
        color: $colorFontDefault;
      }
      &--delete {
        color: $primaryRed !important;
        font-weight: 700;
      }
    }
  }
}
