@import '~mixins';

.org-invite-error-modal {
  .ant-form {
    width: 100%;
    margin-top: toRem(16);
  }
}

.issue-form {
  .ant-form {
    width: 100%;
    margin-top: toRem(16);
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    width: 100%;
    text-align: left;
  }

  .ant-form-item-explain {
    text-align: left;
  }

  textarea {
    height: toRem(115);
    resize: none;
  }
}

.issue-modal {
  .ant-form {
    width: 100%;
    margin-top: toRem(16);
  }

  .ant-modal-content {
    padding: toRem(16);
  }
}