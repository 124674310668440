@import '~variables';
@import '~mixins';

$yellow: #f7eb08;
$black: #121d2a;
$white: #ffffff;

.services-feature-list {
  padding: toRem(22) toRem(26) toRem(20);
  background: $white;
  border-radius: toRem(8);
}

.services-feature-list__title {
  font-family: $primaryFont;
  font-style: normal;
  font-weight: 500;
  font-size: toRem(18);
  line-height: toRem(21);
  letter-spacing: -0.01em;
  color: $black;
}

.services-feature-list__features {
  margin-left: toRem(10);
}

.services-feature-list__collapse .ant-collapse-item {
  border: none;
}

.services-feature-list__collapse.ant-collapse-ghost {
  position: relative;
  min-height: toRem(30);
}

.services-feature-list__collapse > .ant-collapse-item > .ant-collapse-header {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 0;
  padding-top: toRem(8);
  background: transparent;
  justify-content: center;
  font-family: $primaryFont;
  font-style: normal;
  font-weight: 600;
  font-size: toRem(14);
  line-height: toRem(24);
  text-align: center;
  color: $black;
}

.services-feature-list__collapse.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0 0 toRem(35);
}
