@import '~variables';
@import '~mixins';

.user-groups-form {
  &__modal {
    .ant-modal-body {
      min-height: toRem(250);
    }
  }

  &__search-container {
    .ant-btn.ant-input-search-button {
      background: none;
      height: toRem(32);
      cursor: pointer;
    }
    .ant-input-wrapper input.ant-input {
      border: 0;
    }

    .ant-input-group-addon {
      left: -4px;
      background: 0;
    }
  }

  .ant-transfer-list-search .ant-input {
    background: 0;
  }
  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: $primaryBlueLightest;
    box-shadow: none;
  }
}
