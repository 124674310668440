@import '~mixins';
@import '~variables';
@import '~mediaQueries';

.payment-modal-input {
  @include input;
  height: toRem(39);
  width: 100%;
}
.payment-modal-button {
  margin: 0 toRem(16);
}
.payment-modal-title {
  font-size: toRem(16);
  font-weight: 500;
  color: $primaryOrange;
  @include respond-above(sm) {
    font-size: toRem(20);
  }
}
.payment-modal-wrapper .ant-modal {
  .modal__content {
    padding-bottom: toRem(100);
  }
  &.modal-wrapper {
    .ant-modal-close {
      display: block;
    }
  }
}
.payment-modal-image {
  display: none;

  @include respond-above(sm) {
    width: 50%;
    margin-left: toRem(30);
    margin-bottom: toRem(-14);
    align-self: end;
    display: flex;
    justify-content: center;
  }
}
.payment-modal-amount {
  width: 100%;
  @include respond-above(sm) {
    width: 50%;
    align-self: end;
  }
}
.payment-modal-spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  backdrop-filter: $filter !important;

  .overlay-spinner {
    height: 100%;
  }
}

.payment-modal-redirect-text {
  font-size: toRem(16);
  margin-top: toRem(50);
  text-align: center;
  @include respond-above(sm) {
    font-size: toRem(20);
  }
}
