@import '~variables';
@import '~mixins';
@import '~mediaQueries';

$white: #ffffff;
$yellow: #ffdf32;
$black: #121d2a;
$gray: #d4d4d4;
$box-shadow: 0 toRem(8) toRem(5) rgb(182 182 182 / 75%);
$background: #f6f8fa;
$bodyBackground: #f5f5f5;

.services {
  position: relative;
  max-width: toRem(1160);
  margin-left: auto;
  margin-right: auto;
  padding: toRem(63) toRem(20) toRem(36);
  background: $white;
  border-radius: toRem(10);
  z-index: 1;
}

.services__wrapper {
  padding-top: toRem(115);
  padding-bottom: toRem(115);
  background: $bodyBackground;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    transform: translate(-50%, toRem(115));
    top: 0;
    left: 51%;
    width: 100%;
    height: 100%;
    background-image: url('./asserts/spots-background.svg');
    background-repeat: no-repeat;
    background-position-x: center;
    z-index: 1;
  }
}

.services h1,
h2,
h3,
h4,
h5 {
  &.ant-typography.services__title {
    margin: 0;
  }
}

.services__header {
  position: relative;
  margin-bottom: toRem(47);
}

.services__title {
  font-family: $primaryFont;
  font-style: normal;
  font-weight: 600;
  font-size: toRem(48);
  line-height: toRem(57);
  text-align: center;
  color: $black;
}

.services__sub-title {
  font-family: $primaryFont;
  font-style: normal;
  font-weight: 400;
  font-size: toRem(16);
  line-height: toRem(19);
  text-align: center;
  color: $black;
  opacity: 0.8;
}

.services__interval {
  .ant-radio-group {
    padding: toRem(12) toRem(15) toRem(11);
    background: $yellow;
    height: toRem(64);
    display: flex;
    gap: toRem(10);
    border-radius: toRem(8);
  }

  .ant-radio-button-wrapper {
    padding: toRem(6) toRem(10);
    background: none;
    border: none;
  }

  .ant-radio-button-wrapper::before {
    background: none;
  }

  .ant-radio {
    display: none;
  }

  span.ant-radio + * {
    font-family: $primaryFont;
    font-style: normal;
    font-weight: 400;
    font-size: toRem(18);
    line-height: toRem(21);
    color: $black;
  }

  .ant-radio-wrapper {
    margin-right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: toRem(6) toRem(10);
    border-radius: toRem(4);
    transition: 300ms;
  }

  .ant-radio-wrapper-checked {
    margin-right: 0;
    background: $white;
  }
}

.services__total {
  margin-top: toRem(5);
  margin-bottom: toRem(5);
  font-family: $primaryFont;
  font-style: normal;
  font-weight: 500;
  font-size: toRem(50);
  line-height: toRem(59);
  display: flex;
  align-items: flex-end;
  gap: toRem(10);
  text-align: right;
  letter-spacing: -0.01em;
  color: $black;

  span {
    margin-bottom: toRem(5);
    text-transform: lowercase;
    font-family: $primaryFont;
    font-style: normal;
    font-weight: 500;
    font-size: toRem(22);
    line-height: toRem(26);
    align-items: flex-end;
    letter-spacing: -0.01em;
    color: $black;
    opacity: 0.5;
  }
}

.services__tabs .ant-tabs-nav {
  flex-direction: column-reverse;
}

.services__tabs .ant-tabs-ink-bar {
  display: none;
}

.services__tabs .ant-tabs-nav .ant-tabs-tab {
  padding: toRem(20) toRem(20);
}

.services__tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  border: toRem(1) solid $yellow;
}

.services__tabs .ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-active) {
  background: $background;
  border-color: $background;
}

.services__tabs .ant-tabs-tab-btn {
  width: 100%;
  height: 100%;
}

.services__tabs .ant-tabs-left > .ant-tabs-content-holder {
  border: none;
}

.services__btn {
  width: toRem(255);
  height: toRem(58);
  border-radius: toRem(10);

  &--outline {
    border: toRem(2) solid $yellow;

    &:hover,
    &:focus,
    &:active {
      background: $yellow;
      border-color: $yellow;
    }
  }

  &--primary {
    background: $yellow;
    border-color: $yellow;
    &:hover,
    &:focus,
    &:active {
      border: toRem(2) solid $yellow;
    }
  }

  &--secondary {
    background: rgba(18, 29, 42, 0.1);

    &:hover,
    &:focus,
    &:active {
      border: toRem(2) solid rgba(18, 29, 42, 0.1);
    }

    &:disabled {
      opacity: 0.4;
    }
  }

  span {
    font-family: $primaryFont;
    font-style: normal;
    font-weight: 500;
    font-size: toRem(16);
    line-height: toRem(19);
    color: $black;
  }
}

.services__tabs .ant-tabs {
  flex-direction: column;
  position: relative;
}

.services .ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: sticky;
  top: 0;
  background: $primaryWhite;
  border-bottom: toRem(1) solid $gray;
  box-shadow: $box-shadow;
  margin: 0 toRem(-20);
  z-index: 1;
}

.services__btns {
  padding-left: toRem(1);
  padding-right: toRem(1);
}

.services__contact-btn {
  width: 100%;
}

@include respond-above(sm) {
  .services__contact-btn {
    width: toRem(255);
  }
}

@include respond-above(md) {
  .services {
    padding: toRem(63) toRem(47) toRem(36);
  }

  .services__total {
    margin-top: toRem(30);
    margin-bottom: toRem(30);
  }

  .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin-top: toRem(9);
    padding: toRem(21) toRem(30) toRem(24) toRem(24);
  }

  .services__tabs .ant-tabs-nav .ant-tabs-tab {
    height: toRem(130);
  }

  .services__tabs .ant-tabs {
    flex-direction: unset;
  }

  .services .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    max-width: toRem(374);
    position: unset;
    border: unset;
    box-shadow: none;
    margin-right: toRem(9);
    margin-left: 0;
  }

  .services__tabs
    .ant-tabs-card.ant-tabs-left
    > .ant-tabs-nav
    .ant-tabs-tab:first-child {
    border-radius: toRem(20) 0 0 0;
    padding: toRem(21) toRem(32) toRem(20) toRem(25);
  }

  .services__tabs .ant-tabs-card.ant-tabs-left .ant-tabs-tab:nth-child(3) {
    border-radius: 0 0 0 toRem(20);
  }
}
