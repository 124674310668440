@import '~mixins';
@import '~variables';
@import '~mediaQueries';

.product-form__form-grid {
  .col-flex--three {
    width: auto;
    display: block;
    width: 100%;
    @include respond-above(md) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .form-item-wrapper {
        width: calc(100% - 16px / 3);
        &:nth-child(2) {
          margin: 0 toRem(16);
          align-self: self-end;
        }
        &:last-child {
          align-self: center;
          margin: 0;
          .ant-row {
            &.ant-form-item {
              &.ant-form-item__checkbox {
                margin-bottom: 0;
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  .col-flex--four {
    width: auto;
    display: grid;
    grid-template-columns: 2fr 50%;
    width: 100%;
    .form-item-wrapper {
      width: 100%;
      &:nth-child(even) {
        margin: 0 toRem(8);
        align-self: self-end;
      }
    }
    @include respond-above(md) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .form-item-wrapper {
        width: calc(100% - 16px / 4);
        &:nth-child(even) {
          margin: 0 toRem(8);
          align-self: self-end;
        }
      }
    }
  }
}
