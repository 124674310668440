.account-default-error-modal.ant-modal .ant-modal-footer {
  padding-top: 0;
}

.account-default-error-modal .ant-modal-title {
  text-align: center;
}

.ant-modal-body {
  padding: 0;
}