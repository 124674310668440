@import '~variables';
@import '~mixins';

.incident-k-request-tabs__state-tab {
  margin-left: toRem(-14);
}

.incident-k-request-tabs h3 {
  font-weight: 500;
}

