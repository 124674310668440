@import '~variables';
@import '~mixins';

.share-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: toRem(10);

  input {
    opacity: 0;
    position: absolute;
    width: toRem(120);
  }

  &__icon {
    color: $primaryBlueNew;
    line-height: toRem(22);
  }

  .ant-btn:hover {
    .icon {
      &:before {
        color: $primaryWhite;
      }
    }
  }
}
