@import '~variables';
@import '~mixins';

.content-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo-icon {
  margin-top: toRem(65);
  margin-bottom: toRem(290);
}
.message-container {
  width: auto;
  height: fit-content;
  border: $grayBorder;
  background: $primaryWhite;
  border-radius: toRem(16);
  box-shadow: $primaryBoxShadow;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: toRem(80) toRem(130);
}
.message-container__icon {
  margin-bottom: toRem(40);
}
.message-container__text {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.message-container__button {
  background-color: $primaryButtonOrange;
  margin-top: toRem(40);
  .button__title {
    color: $primaryWhite;
  }
}
