@import '~variables';
@import '~mixins';

.itsm-product-details {

  font-size: toRem(12);
  display: flex;

  &__data-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  div {
    white-space: nowrap;
  }

  &__row {
    position: relative;
    height: toRem(18);
    padding-left: toRem(20);
  }

  &__support-data {
    left: toRem(32);
    position: absolute;
  }
}